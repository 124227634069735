import { modifyStorageQuestionDetails, ModifyQuestionDetails } from "../../stores/SurveyEditStore";

import { useCallback } from "react";

import { Question, MultiQuestion, MultiQuestionType } from "reliance-private-survey-schema/dist/API";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function MultiQuestionEdit(props: { q: Question }) {
    var q = props.q as unknown as MultiQuestion;

    const handleChange = useCallback(
        (fieldName: string, fieldValue: string) => {
            var payload: ModifyQuestionDetails = {
                id: q.id!,
                fieldName: fieldName,
                fieldValue: fieldValue,
            };
            if (fieldName.includes(".")) {
                const x = fieldName.split(".");
                const newList = (q[x[0]] ?? []) as string[];
                newList[x[1]] = fieldValue;
                payload.fieldName = x[0];
                payload.fieldValue = newList;
                modifyStorageQuestionDetails(payload);
            } else {
                modifyStorageQuestionDetails(payload);
            }
        },
        [q]
    );

    const removeOption = useCallback(
        (fieldName: string, index: number) => {
            var newList = (q[fieldName] ?? []) as string[];
            newList.splice(index, 1);
            var payload: ModifyQuestionDetails = {
                id: q.id!,
                fieldName: fieldName,
                fieldValue: newList,
            };
            modifyStorageQuestionDetails(payload);
        },
        [q]
    );

    const addOption = useCallback(
        (fieldName: string) => {
            var newList = (q[fieldName] ?? []) as string[];
            newList.push("NEW");
            var payload: ModifyQuestionDetails = {
                id: q.id!,
                fieldName: fieldName,
                fieldValue: newList,
            };
            modifyStorageQuestionDetails(payload);
        },
        [q]
    );

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(q.options, result.source.index, result.destination.index) as string[];
        var payload: ModifyQuestionDetails = {
            id: q.id!,
            fieldName: "options",
            fieldValue: items,
        };
        modifyStorageQuestionDetails(payload);
    };

    return (
        <form>
            <div>
                <div>
                    <b>Multi Question Type:</b>
                    <span className="px-3">
                        <select
                            id="multiQuestionType"
                            name="multiQuestionType"
                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            value={q?.multiQuestionType ?? ""}
                            onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                        >
                            <option value=""></option>
                            {Object.keys(MultiQuestionType).map((k) => (
                                <option key={k} value={k}>
                                    {k}
                                </option>
                            ))}
                        </select>
                    </span>
                </div>
                {["CHECKBOXES"].includes(String(q.multiQuestionType)) && (
                    <div>
                        <b>
                            Options:
                            <button type="button" onClick={() => addOption("options")}>
                                ADD+
                            </button>
                        </b>
                        <span className="px-3">
                            <DragDropContext className="border-4" onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {q.options?.map((opt, index) => (
                                                <Draggable key={index} draggableId={String(index)} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div className="container border-4 border-dashed border-gray-200 rounded-lg mb-4 flex flex-co">
                                                                <p>DRAGME</p>
                                                                <div key={index}>
                                                                    <input
                                                                        type="text"
                                                                        name={`options.${index}`}
                                                                        value={opt!}
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                e.target.name!,
                                                                                e.target.value!
                                                                            )
                                                                        }
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => removeOption("options", index)}
                                                                    >
                                                                        X
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </span>
                    </div>
                )}
            </div>
        </form>
    );
}
export default MultiQuestionEdit;
