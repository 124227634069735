import {
    modifyStorageQuestionDetails,
    ModifyQuestionDetails,
    ModifyBehalfOfLabelDetails,
    modifyBehalfOfLabelChange,
} from "../../stores/SurveyEditStore";

import { useCallback } from "react";

import { Question, SingleQuestion, SingleQuestionType } from "reliance-private-survey-schema/dist/API";

import { Droppable, Draggable } from "react-beautiful-dnd";

import SurveyEditStore from "../../stores/SurveyEditStore";
import { useStore } from "effector-react";

function SingleQuestionEdit(props: { q: Question }) {
    const survey_edit = useStore(SurveyEditStore);
    var q = props.q as unknown as SingleQuestion;

    const handleChange = useCallback(
        (fieldName: string, fieldValue: string) => {
            var payload: ModifyQuestionDetails = {
                id: q.id!,
                fieldName: fieldName,
                fieldValue: fieldValue,
            };
            if (fieldName.includes(".")) {
                const x = fieldName.split(".");
                const newList = (q[x[0]] ?? []) as string[];
                newList[x[1]] = fieldValue;
                payload.fieldName = x[0];
                payload.fieldValue = newList;
                modifyStorageQuestionDetails(payload);
            } else {
                modifyStorageQuestionDetails(payload);
            }
        },
        [q]
    );

    const handleBehalfOfLabelChange = (fieldName: string, fieldValue: string) => {
        var payload: ModifyBehalfOfLabelDetails = {
            name: fieldName,
            value: fieldValue,
        };
        modifyBehalfOfLabelChange(payload);
    };

    const removeOption = useCallback(
        (fieldName: string, index: number) => {
            var newList = (q[fieldName] ?? []) as string[];
            newList.splice(index, 1);
            var payload: ModifyQuestionDetails = {
                id: q.id!,
                fieldName: fieldName,
                fieldValue: newList,
            };
            modifyStorageQuestionDetails(payload);
        },
        [q]
    );

    const addOption = useCallback(
        (fieldName: string) => {
            var newList = (q[fieldName] ?? []) as string[];
            newList.push("NEW");
            var payload: ModifyQuestionDetails = {
                id: q.id!,
                fieldName: fieldName,
                fieldValue: newList,
            };
            modifyStorageQuestionDetails(payload);
        },
        [q]
    );

    return (
        <form>
            <div>
                <div>
                    <b>Single Question Type:</b>
                    <span className="px-3">
                        <select
                            id="singleQuestionType"
                            name="singleQuestionType"
                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            value={q?.singleQuestionType ?? ""}
                            onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                        >
                            <option value=""></option>
                            {Object.keys(SingleQuestionType).map((k) => (
                                <option key={k} value={k}>
                                    {k}
                                </option>
                            ))}
                        </select>
                    </span>
                </div>
                {["RADIO", "SELECT"].includes(String(q.singleQuestionType)) && (
                    <div>
                        <b>
                            Options:
                            <button type="button" onClick={() => addOption("options")}>
                                ADD+
                            </button>
                        </b>
                        <span className="px-3">
                            <Droppable droppableId="options">
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {q.options?.map((opt, index) => (
                                            <Draggable key={index} draggableId={String(index)} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className="container border-4 border-dashed border-gray-200 rounded-lg mb-4 flex flex-co">
                                                            <p>DRAGME</p>
                                                            <div key={index}>
                                                                {q.questionToken === "behalf_of" &&
                                                                ["Self", "Loved One", "Someone Else"].includes(opt!) ? (
                                                                    <div>
                                                                        <div className="pl-2 font-bold">{opt}</div>
                                                                        <input
                                                                            type="text"
                                                                            name={
                                                                                opt?.toLowerCase() === "self"
                                                                                    ? "selfLabel"
                                                                                    : "otherLabel"
                                                                            }
                                                                            value={
                                                                                survey_edit.sourceSurvey
                                                                                    ?.behalfOfLabel?.[
                                                                                    opt?.toLowerCase() === "self"
                                                                                        ? "selfLabel"
                                                                                        : "otherLabel"
                                                                                ] ?? opt!
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleBehalfOfLabelChange(
                                                                                    e.target.name!,
                                                                                    e.target.value!
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <input
                                                                        type="text"
                                                                        name={`options.${index}`}
                                                                        value={opt!}
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                e.target.name!,
                                                                                e.target.value!
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                                {q.questionToken === "behalf_of" &&
                                                                ["Self", "Loved One", "Someone Else"].includes(opt!) ? (
                                                                    <span />
                                                                ) : (
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => removeOption("options", index)}
                                                                    >
                                                                        X
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </span>
                    </div>
                )}
            </div>
        </form>
    );
}
export default SingleQuestionEdit;
