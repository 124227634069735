/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// stage

const awsmobile = {
    aws_project_region: "us-west-2",
    aws_appsync_graphqlEndpoint: "https://75bv7hv33jdx5mfou57txtrh3a.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_cognito_region: "us-west-2",
    aws_user_pools_id: "us-west-2_ksqQ6U0b9",
    aws_user_pools_web_client_id: "2inlai6opvglksi6ufn0pahtu3",
    aws_cognito_identity_pool_id: "us-west-2:a83ac5a9-18aa-4000-8570-eda904c74270",
    Auth: {
        region: "us-west-2",
        userPoolId: "us-west-2_ksqQ6U0b9",
        userPoolWebClientId: "2inlai6opvglksi6ufn0pahtu3",
        identityPoolId: "us-west-2:a83ac5a9-18aa-4000-8570-eda904c74270",
    },
    mandatorySignIn: true,
    oauth: {
        domain: "stage.auth.reliancelitigation.com",
        scope: ["email", "profile", "openid"],
        redirectSignIn: "https://stage.surveybuilder.reliancelitigation.com:4443/",
        redirectSignOut: "https://stage.surveybuilder.reliancelitigation.com:4443/",
        responseType: "code",
    },
};

export default awsmobile;
