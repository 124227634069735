import SurveyEditStore from "../stores/SurveyEditStore";
import { useStore } from "effector-react";

import { Droppable, Draggable } from "react-beautiful-dnd";

import QuestionBox from "./Questions/QuestionBox";
import NewQuestionBox from "./Questions/NewQuestionBox";

import { useState } from "react";

function QuestionList(props: { survey: string }) {
    const survey_edit = useStore(SurveyEditStore);
    const [isAdding, isAddingSet] = useState(false);

    return (
        <>
            <div className="mb-40">
                <Droppable droppableId="questionList">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {survey_edit[`${props.survey}Survey`]?.linkedQuestions?.map((lq, index) => (
                                <Draggable key={lq?.id} draggableId={`Question#${lq?.id}}`} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <QuestionBox q={lq?.question!} survey={props.survey} />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                {isAdding && <NewQuestionBox closeCallback={isAddingSet} survey={props.survey} />}

                {!isAdding && (
                    <button
                        type="button"
                        className="m-6 p-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() => isAddingSet(!isAdding)}
                    >
                        ADD NEW
                    </button>
                )}
            </div>
        </>
    );
}
export default QuestionList;
