import { modifyStorageQuestionDetails, ModifyQuestionDetails } from "../../stores/SurveyEditStore";

import { useCallback } from "react";

import { Question, DateQuestion, DateQuestionType } from "reliance-private-survey-schema/dist/API";

function DateQuestionEdit(props: { q: Question }) {
    var q = props.q as unknown as DateQuestion;

    const handleChange = useCallback(
        (fieldName: string, fieldValue: string) => {
            var payload: ModifyQuestionDetails = {
                id: q.id!,
                fieldName: fieldName,
                fieldValue: fieldValue,
            };
            if (fieldName.includes(".")) {
                const x = fieldName.split(".");
                const newList = (q[x[0]] ?? []) as string[];
                newList[x[1]] = fieldValue;
                payload.fieldName = x[0];
                payload.fieldValue = newList;
                modifyStorageQuestionDetails(payload);
            } else {
                modifyStorageQuestionDetails(payload);
            }
        },
        [q]
    );

    return (
        <form>
            <div>
                <div>
                    <b>Date Question Type:</b>
                    <span className="px-3">
                        <select
                            id="dateQuestionType"
                            name="dateQuestionType"
                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            value={q?.dateQuestionType ?? ""}
                            onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                        >
                            <option value=""></option>
                            {Object.keys(DateQuestionType).map((k) => (
                                <option key={k} value={k}>
                                    {k}
                                </option>
                            ))}
                        </select>
                    </span>
                </div>
            </div>
        </form>
    );
}
export default DateQuestionEdit;
