import { Question, DateQuestion } from "reliance-private-survey-schema/dist/API";

function DateQuestionBox(props: { q: Question }) {
    const q = props.q as unknown as DateQuestion;

    return (
        <>
            <div>
                {q!.dateQuestionType === "DATE" && (
                    <div>
                        <input placeholder="mm/dd/YYYY" type="date" className="form-text" name="value" />
                    </div>
                )}
                {q!.dateQuestionType === "DATETIME" && (
                    <div>
                        <input
                            placeholder="mm/dd/YYYY hh:mm a"
                            type="datetime-local"
                            className="form-text"
                            name="value"
                        />
                    </div>
                )}
                {q!.dateQuestionType === "START_END_DATE" && (
                    <div>
                        <input placeholder="START mm/dd/YYYY" type="date" className="form-text" name="values.0" />
                        <input placeholder="END mm/dd/YYYY" type="date" className="form-text" name="values.1" />
                    </div>
                )}
            </div>
        </>
    );
}
export default DateQuestionBox;
