import { API } from "aws-amplify";

import { addSurveyFromSessionBasedContextConfigurationSetShort } from "reliance-private-survey-schema/dist/customGraphql/common";

import { PromotedSessionBasedContextConfiguration, Survey } from "reliance-private-survey-schema/dist/API";

import FillOutSurvey from "../components/Survey/FillOutSurvey";

import { useParams } from "react-router-dom";
import { useEffect, useCallback, useState } from "react";

import { promoteSessionBasedContextConfigurationSet } from "reliance-private-survey-schema/dist/graphql/mutations";

type routeParams = {
    sessionId: string;
};
function Preview() {
    const route_params: routeParams = useParams();
    const [surveyId, surveyIdSet] = useState("");
    /// when we want to promote it
    const promote = useCallback(async () => {
        const response: any = await API.graphql({
            query: promoteSessionBasedContextConfigurationSet,
            variables: { id: route_params.sessionId },
        });
        const responseTyped: PromotedSessionBasedContextConfiguration =
            response.data.promoteSessionBasedContextConfigurationSet;

        alert(`Promoted! ${responseTyped.id}`);
    }, [route_params.sessionId]);
    //// when we load, we need to create a survey off the Session ID
    useEffect(() => {
        async function setSurvey(sessionId) {
            const response: any = await API.graphql({
                query: addSurveyFromSessionBasedContextConfigurationSetShort,
                variables: { id: route_params.sessionId },
            });
            const responseTypedSurvey: Partial<Survey> = response.data.addSurveyFromSessionBasedContextConfigurationSet;
            surveyIdSet(responseTypedSurvey.id!);
        }
        setSurvey(route_params.sessionId);
    }, [route_params.sessionId]);
    return (
        <main className="flex-1 relative h-screen overflow-y-auto focus:outline-none">
            <div className="bg-blue-500">
                <span className="px-2">You are previewing a test survey!</span>
                <button
                    className={`bg-white hover:bg-gray-100 text-s text-gray-800 font-semibold py-0 px-0 border border-gray-400 rounded shadow`}
                    onClick={() => promote()}
                >
                    PROMOTE
                </button>
            </div>
            <div className="">
                {!surveyId && <p>CREATING SURVEY FROM CONFIGURATIONS...</p>}
                {surveyId && <FillOutSurvey surveyId={surveyId} />}
            </div>
        </main>
    );
}
export default Preview;
