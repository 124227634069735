import SurveyEditStore, { addExistingQuestion } from "../stores/SurveyEditStore";
import { useStore } from "effector-react";

function QuestionSearch() {
    const survey_edit = useStore(SurveyEditStore);
    return (
        <div className="p-2">
            {Object.keys(survey_edit.allQuestionsOriginal)
                .filter((v) => {
                    return (
                        survey_edit.allQuestionsOriginal[v].sourceId.toLowerCase() ===
                        survey_edit.UiMainSection.toLowerCase()
                    );
                })
                .map((e) => {
                    return (
                        <div key={e}>
                            <p className="pl-2 border-b">
                                <button onClick={() => addExistingQuestion(e)}>&lt;==</button>
                                <span className="pl-2">{e}</span>
                            </p>
                        </div>
                    );
                })}
        </div>
    );
}
export default QuestionSearch;
