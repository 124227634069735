import SurveyEditStore, {
    resetEditingQuestion,
    saveEditingQuestion,
    addCondition,
    modifyStorageQuestionDetails,
    ModifyQuestionDetails,
    removeQuestion,
    ModifyBehalfOfLabelDetails,
    modifyBehalfOfLabelChange,
} from "../../stores/SurveyEditStore";
import { useStore } from "effector-react";

import { useCallback } from "react";

import SingleQuestionEdit from "../../components/Questions/SingleQuestionEdit";
import MultiQuestionEdit from "../../components/Questions/MultiQuestionEdit";
import DateQuestionEdit from "../../components/Questions/DateQuestionEdit";
import PersonQuestionEdit from "../../components/Questions/PersonQuestionEdit";
import AddressQuestionEdit from "../../components/Questions/AddressQuestionEdit";

import ConditionBox from "../Comparisons/ConditionBox";

function QuestionEdit() {
    const survey_edit = useStore(SurveyEditStore);
    const qData = survey_edit.selectedEditQuestion;
    const q = survey_edit.selectedEditQuestionStorage[qData!.id];

    const handleChange = useCallback(
        (fieldName: string, fieldValue: string | boolean) => {
            var payload: ModifyQuestionDetails = {
                id: q.id!,
                fieldName: fieldName,
                fieldValue: fieldValue,
            };
            if (fieldName.includes(".")) {
                const x = fieldName.split(".");
                const newList = (q[x[0]] ?? []) as string[];
                newList[x[1]] = fieldValue;
                payload.fieldName = x[0];
                payload.fieldValue = newList;
                modifyStorageQuestionDetails(payload);
            } else {
                modifyStorageQuestionDetails(payload);
            }
        },
        [q]
    );

    const handleBehalfOfLabelChange = (fieldName: string, fieldValue: string) => {
        var payload: ModifyBehalfOfLabelDetails = {
            name: fieldName,
            value: fieldValue,
        };
        modifyBehalfOfLabelChange(payload);
    };

    return (
        <div className="p-2 mb-40">
            {q && (
                <div className="flex flex-col">
                    <div>
                        <b>Type:</b>
                        <span className="px-3">Question</span>
                    </div>
                    <div>
                        <b>Question ID:</b>
                        <span className="px-3">{q.id}</span>
                    </div>
                    <div>
                        <b>Survey:</b>
                        <span className="px-3">{qData?.context}</span>
                    </div>
                    <div>
                        <b>Question Type:</b>
                        <span className="px-3">{q.questionType}</span>
                    </div>
                    <div>
                        <b>Label:</b>
                        <span className="px-3">
                            <input
                                type="text"
                                id="label"
                                name="label"
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                value={q?.label ?? ""}
                                onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                            />
                        </span>
                    </div>
                    <div>
                        <b>Text:</b>
                        <span className="px-3">
                            <input
                                type="text"
                                id="text"
                                name="text"
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                value={q?.text ?? ""}
                                onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                            />
                        </span>
                    </div>
                    <div>
                        <b>Content Image:</b>
                        <span className="px-3">
                            <input
                                type="text"
                                id="contentImage"
                                name="contentImage"
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                value={q?.contentImage ?? ""}
                                onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                            />
                        </span>
                    </div>
                    {q.questionToken === "behalf_of" && (
                        <div>
                            <b>Text (Tort Override):</b>
                            <span className="px-3">
                                <input
                                    type="text"
                                    id="textLabel"
                                    name="textLabel"
                                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                    value={survey_edit.sourceSurvey?.behalfOfLabel?.textLabel ?? q?.text ?? ""}
                                    onChange={(e) => handleBehalfOfLabelChange(e.target.name!, e.target.value!)}
                                />
                            </span>
                        </div>
                    )}
                    {q.questionToken !== "behalf_of" && (
                        <div>
                            <b>Optional?:</b>
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    className="form-radio"
                                    name="optional"
                                    value="Yes"
                                    checked={Boolean(q.optional)}
                                    onChange={(e) => handleChange(e.target.name!, e.target.checked)}
                                />
                            </label>
                        </div>
                    )}

                    {q.questionToken !== "behalf_of" && ["source", "global"].includes(qData?.context ?? "") && (
                        <div>
                            <b>Firm Opt-In Required:</b>
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    className="form-radio"
                                    name="optIn"
                                    value="Yes"
                                    checked={Boolean(q.optIn)}
                                    onChange={(e) => handleChange(e.target.name!, e.target.checked)}
                                />
                            </label>
                        </div>
                    )}
                    {qData?.context === "global" && (
                        <div>
                            <b>Section:</b>
                            <select
                                id="section"
                                name="section"
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                value={q?.section ?? ""}
                                onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                            >
                                <option value=""></option>
                                {["PRE", "POST"].map((v) => {
                                    return (
                                        <option key={v} value={v!}>
                                            {v}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}
                    {q.questionType === "SingleQuestion" && <SingleQuestionEdit q={q} />}
                    {q.questionType === "MultiQuestion" && <MultiQuestionEdit q={q} />}
                    {q.questionType === "DateQuestion" && <DateQuestionEdit q={q} />}
                    {q.questionType === "PersonQuestion" && <PersonQuestionEdit q={q} />}
                    {q.questionType === "AddressQuestion" && <AddressQuestionEdit q={q} />}
                    <div>
                        <b>Show Conditions:</b>{" "}
                        <button type="button" onClick={() => addCondition()}>
                            ADD+
                        </button>
                        <span className="px-3">
                            {q.conditions?.map((cond, index) => (
                                <ConditionBox cond={cond!} key={index} index={index} />
                            ))}
                        </span>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="m-6 p-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => saveEditingQuestion()}
                        >
                            SUBMIT
                        </button>
                        <button
                            type="button"
                            className="m-6 p-6 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => resetEditingQuestion()}
                        >
                            RESET
                        </button>
                        <button
                            type="button"
                            className="m-6 p-6 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => removeQuestion()}
                        >
                            REMOVE
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
export default QuestionEdit;
