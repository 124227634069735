import { Question, PersonQuestion } from "reliance-private-survey-schema/dist/API";

function PersonQuestionBox(props: { q: Question }) {
    const q = props.q as unknown as PersonQuestion;

    return (
        <>
            <div>
                <div>
                    <input type="text" className="form-text" name="first" placeholder="First Name" />
                    <input type="text" className="form-text" name="middle" placeholder="Middle Name" />
                    <input type="text" className="form-text" name="last" placeholder="Last Name" />
                </div>
                {["COMPLETE", "COMPLETE_GENDER"].includes(q!.personQuestionType) && (
                    <>
                        <div>
                            <input type="email" className="form-text" name="email" placeholder="Email" />
                        </div>
                        <div>
                            <input type="text" className="form-text" name="phone" placeholder="Phone" />
                        </div>
                    </>
                )}
                {["SIMPLE_GENDER", "COMPLETE_GENDER"].includes(q!.personQuestionType) && (
                    <>
                        <div>
                            <label className="inline-flex items-center">
                                <input type="radio" className="form-radio" name="value" value="Male" />
                                <span className="ml-2">Male</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input type="radio" className="form-radio" name="value" value="Female" />
                                <span className="ml-2">Female</span>
                            </label>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
export default PersonQuestionBox;
