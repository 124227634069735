import { useState, useEffect, useCallback } from "react";
import { API } from "aws-amplify";

import { ContextConfiguration, Firm, Source, Question } from "reliance-private-survey-schema/dist/API";

import {
    setEditContext,
    PartialSurveyEdit,
    setContextConfiguration,
    setQuestions,
    setDisqualifications,
} from "../stores/SurveyEditStore";

import { useForm, SubmitHandler } from "react-hook-form";

import * as _ from "lodash";

import { getQuestionsBySource } from "reliance-private-survey-schema/dist/graphql/queries";

import {
    getMainRecord,
    fragmentContextConfiguration,
    getMainRecordsBySource,
} from "reliance-private-survey-schema/dist/customGraphql/common";

type Inputs = {
    firmId: string;
    sourceId: string;
};

type ExistingStorage = Record<string, string[]>;

const getQuestionsBySourceRemoveTypename = getQuestionsBySource.replace("__typename", "");

function ContextSelectorEdit() {
    const [firms, firmsSet] = useState([] as Firm[]);
    const [sources, sourcesSet] = useState([] as Source[]);
    const [selectedSource, selectedSourceSet] = useState("");
    const [onlyExisting, onlyExistingSet] = useState(true);
    const [existingSurveysBySource, existingSurveysBySourceSet] = useState<ExistingStorage>({});

    const { register, handleSubmit } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        doSetContext({
            source: sources.filter((source: any, index: number) => source.id === data.sourceId)[0],
            firm: firms.filter((firm: any, index: number) => firm.id === data.firmId)[0],
        });
    };

    const updateExistingSurveys = useCallback(
        async (sourceId: string) => {
            selectedSourceSet(sourceId);
            if (!existingSurveysBySource[sourceId]) {
                const responseGetMainRecordsBySource: any = await API.graphql({
                    query: getMainRecordsBySource,
                    variables: { sourceId: sourceId },
                });
                if (responseGetMainRecordsBySource.data.getMainRecordsBySource !== null) {
                    var newIds: string[] = [];
                    for (let q of responseGetMainRecordsBySource.data.getMainRecordsBySource) {
                        newIds.push(q.firmId);
                    }
                    var newStorage = _.cloneDeep(existingSurveysBySource);
                    newStorage[sourceId] = newIds;
                    existingSurveysBySourceSet(newStorage);
                }
            }
        },
        [existingSurveysBySource]
    );

    const doSetContext = useCallback(async (se: PartialSurveyEdit) => {
        try {
            var promises: any = {};

            /// 0
            promises["global"] = API.graphql({
                query: getMainRecord + fragmentContextConfiguration,
                variables: { firmId: "GLOBAL", sourceId: "GLOBAL" },
            });

            /// 1
            promises["source"] = API.graphql({
                query: getMainRecord + fragmentContextConfiguration,
                variables: { firmId: "GLOBAL", sourceId: se.source!.id },
            });

            /// 2
            promises["firm"] = API.graphql({
                query: getMainRecord + fragmentContextConfiguration,
                variables: { firmId: se.firm!.id, sourceId: se.source!.id },
            });

            /// 3
            promises["questionsGlobal"] = API.graphql({
                query: getQuestionsBySourceRemoveTypename,
                variables: { sourceId: "GLOBAL", includeTest: false },
            });

            /// 3
            promises["questionsSource"] = API.graphql({
                query: getQuestionsBySourceRemoveTypename,
                variables: { sourceId: se.source!.id, includeTest: false },
            });

            var values = await Promise.all([
                promises["global"],
                promises["source"],
                promises["firm"],
                promises["questionsGlobal"],
                promises["questionsSource"],
            ]);

            var blank: Partial<ContextConfiguration> = {
                __typename: "ContextConfiguration",
                added: new Date().toISOString(),
            };

            /// set into state each one we got, or create a new blank
            ["global", "source", "firm"].forEach((survey, index) => {
                switch (survey) {
                    case "global":
                        blank.firmId = "GLOBAL";
                        blank.sourceId = "GLOBAL";
                        break;

                    case "source":
                        blank.firmId = "GLOBAL";
                        blank.sourceId = se.source!.id;
                        break;

                    case "firm":
                        blank.firmId = se.firm!.id;
                        blank.sourceId = se.source!.id;
                        break;
                }

                setContextConfiguration({
                    survey: survey,
                    contextConfiguration: (values[index].data.getMainRecord != null
                        ? _.cloneDeep(values[index].data.getMainRecord.contextConfiguration)
                        : _.cloneDeep(blank)) as ContextConfiguration,
                });
            });
            /// call the method to parse all of the disqualifications
            setDisqualifications();

            /// set into state all the available questions
            var qPayload: Array<Question[]> = [];
            [3, 4].forEach((index) => {
                if (values[index].data.getQuestionsBySource != null) {
                    qPayload.push(values[index].data.getQuestionsBySource as Question[]);
                }
            });
            setQuestions(qPayload);

            /// now that we're prepped, flip to edit mode
            setEditContext({
                isEditing: true,
                firm: se.firm,
                source: se.source,
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        const getFirmsAndSources = /* GraphQL */ `
            query GetFirmsAndSources {
                getFirms {
                    id
                    name
                }
                getSources {
                    id
                    name
                }
            }
        `;

        const loadData = async () => {
            const response: any = await API.graphql({ query: getFirmsAndSources });
            firmsSet(
                response.data.getFirms.sort((a, b) => {
                    const A = a.name!.toUpperCase();
                    const B = b.name!.toUpperCase();
                    let comparison = 0;
                    if (A > B) {
                        comparison = 1;
                    } else if (A < B) {
                        comparison = -1;
                    }
                    return comparison;
                })
            );
            sourcesSet(
                response.data.getSources.sort((a, b) => {
                    const A = a.name!.toUpperCase();
                    const B = b.name!.toUpperCase();
                    let comparison = 0;
                    if (A > B) {
                        comparison = 1;
                    } else if (A < B) {
                        comparison = -1;
                    }
                    return comparison;
                })
            );
        };
        loadData();
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="inline-block pr-2 align-top">
                    <label htmlFor="sourceId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Source
                    </label>
                    <div>
                        <select
                            id="sourceId"
                            name="sourceId"
                            ref={register({ required: true })}
                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            onChange={(e) => updateExistingSurveys(e.target.value)}
                        >
                            <option value=""></option>
                            {sources.map((source: any, index: number) => (
                                <option key={index} value={source.id}>
                                    {source.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="inline-block pr-2 align-top">
                    <label htmlFor="firmId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Firm
                    </label>
                    <div>
                        <select
                            id="firmId"
                            name="firmId"
                            ref={register({ required: true })}
                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        >
                            <option value=""></option>
                            {firms
                                .filter((firm: Firm, index: number) => {
                                    if (onlyExisting) {
                                        return existingSurveysBySource[selectedSource]?.includes(firm.id!)
                                            ? true
                                            : false;
                                    }
                                    return true;
                                })
                                .map((firm: Firm, index: number) => (
                                    <option key={index} value={firm.id}>
                                        #{firm.id} - {firm.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div>
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="form-checkbox"
                                onChange={() => onlyExistingSet(!onlyExisting)}
                                checked={onlyExisting}
                            />
                            <span className="ml-2">Only Show Existing Surveys</span>
                        </label>
                    </div>
                </div>
                <button
                    type="submit"
                    className="mt-7 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                >
                    Go!
                </button>
            </form>
        </>
    );
}
export default ContextSelectorEdit;
