import { removeCondition, addComparison } from "../../stores/SurveyEditStore";
import { Condition } from "reliance-private-survey-schema/dist/API";

import ComparisonBox from "./ComparisonBox";

function ConditionBox(props: { cond: Condition; index: number }) {
    const cond = props.cond;

    return (
        <>
            <div className="border-4 border-dashed border-gray-200 rounded-lg mb-4 flex flex-col">
                <div>
                    Condition #{props.index + 1}{" "}
                    <button type="button" onClick={() => removeCondition(props.index)}>
                        X
                    </button>
                </div>
                <div>
                    <b>Comparisons: </b>
                    <button type="button" onClick={() => addComparison(props.index)}>
                        ADD+
                    </button>
                </div>
                <div>
                    {cond.comparisons?.map((comp, index) => (
                        <ComparisonBox key={index} comp={comp!} index={index} condIndex={props.index} />
                    ))}
                </div>
            </div>
        </>
    );
}

export default ConditionBox;
