import { createEvent, createStore } from "effector-logger";

import {
    emptyState,
    SurveyFill,
    ModifyStorageAnswerDetails,
} from "reliance-private-survey-parser/dist/surveyParseTypes";
import { doParseSurvey } from "reliance-private-survey-parser/dist/surveyParseUtils";

export const setFillContext = createEvent<Partial<SurveyFill>>();
export const modifyStorageAnswerDetails = createEvent<ModifyStorageAnswerDetails>();
export const parseSurvey = createEvent();

const debounce = (fn: Function, ms = 400) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
};
var debouncedParseSurvey = debounce(() => parseSurvey());

// /// debounce and watch state when answers change
modifyStorageAnswerDetails.watch(() => debouncedParseSurvey());

// /// initial load parse
setFillContext.watch(() => parseSurvey());

//// STORE
export default createStore<SurveyFill>(emptyState)
    .on(setFillContext, (state, payload) => {
        return {
            ...state,
            ...payload,
        };
    })
    .on(modifyStorageAnswerDetails, (state, payload) => {
        var fieldNameList = payload.fieldName.split("#id#");
        var fieldName = fieldNameList[0];

        // check if it's an array and initialize if needed
        if (fieldName.includes(".")) {
            var fnSplit = fieldName.split(".");
            if (state.questionStorage[payload.questionToken].questionType === "MultiQuestion") {
                if (
                    state.answerStorage[payload.questionToken][fnSplit[0]] === undefined ||
                    state.answerStorage[payload.questionToken][fnSplit[0]] === null
                ) {
                    state.answerStorage[payload.questionToken][fnSplit[0]] = [] as string[];
                }
                state.answerStorage[payload.questionToken][fnSplit[0]][fnSplit[1]] = payload.fieldValue;
            } else {
                if (
                    state.answerStorage[payload.questionToken][fnSplit[0]] === undefined ||
                    state.answerStorage[payload.questionToken][fnSplit[0]] === null
                ) {
                    state.answerStorage[payload.questionToken][fnSplit[0]] = [] as string[];
                }
                state.answerStorage[payload.questionToken][fnSplit[0]][fnSplit[1]] = payload.fieldValue;
            }
        } else {
            // console.log(`tryign to set ${payload.questionToken} ${fieldName} to ${payload.fieldValue}`);
            state.answerStorage[payload.questionToken][fieldName] = payload.fieldValue;
        }
        return {
            ...state,
        };
    })
    .on(parseSurvey, (state) => {
        doParseSurvey(state);
        return {
            ...state,
        };
    });
