import SurveyEditStore, {
    modifyComparisonDetails,
    modifyComparisonValues,
    ModifyComparisonDetails,
    ModifyComparisonValuesDetails,
} from "../../stores/SurveyEditStore";
import { useStore } from "effector-react";

import { SingleComparison, SingleComparisonOperator, SingleQuestion } from "reliance-private-survey-schema/dist/API";

import { useCallback } from "react";

import { States } from "reliance-private-survey-parser/dist/surveyParseTypes";

import * as _ from "lodash";

function SingleComparisonEdit(props: { comp: SingleComparison; index: number; condIndex: number }) {
    const survey_edit = useStore(SurveyEditStore);

    const q = survey_edit.allQuestions[props.comp.questionId!] as unknown as SingleQuestion;

    const handleChange = useCallback(
        (fieldName: string, fieldValue: string) => {
            var payload: ModifyComparisonDetails = {
                comp: _.cloneDeep(props.comp),
                index: props.index,
                condIndex: props.condIndex,
                fieldName: fieldName,
                fieldValue: fieldValue,
            };
            modifyComparisonDetails(payload);
        },
        [props]
    );

    const toggleOption = useCallback(
        (fieldValue: string) => {
            var payload: ModifyComparisonValuesDetails = {
                comp: _.cloneDeep(props.comp),
                index: props.index,
                condIndex: props.condIndex,
                type: "SingleComparison",
                value: fieldValue,
            };
            modifyComparisonValues(payload);
        },
        [props]
    );

    return (
        <>
            {q && (
                <div>
                    <div>
                        <b>Operator:</b>
                        <span className="px-3">
                            <select
                                id="singleComparisonOperator"
                                name="singleComparisonOperator"
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                value={props.comp.singleComparisonOperator}
                                onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                            >
                                <option value=""></option>
                                {Object.keys(SingleComparisonOperator).map((k) => (
                                    <option key={k} value={k}>
                                        {k}
                                    </option>
                                ))}
                            </select>
                        </span>
                    </div>
                    {["EQUALS", "NOT_EQUALS"].includes(props.comp.singleComparisonOperator ?? "") && (
                        <>
                            {/* SINGLE values */}
                            {["RADIO", "SELECT"].includes(q.singleQuestionType ?? "") && (
                                <div>
                                    <b>Value:</b>
                                    <span className="px-3">
                                        <select
                                            id="value"
                                            name="value"
                                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                            value={props.comp.value ?? ""}
                                            onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                        >
                                            <option value=""></option>
                                            {(
                                                survey_edit.allQuestions[
                                                    props.comp.questionId!
                                                ] as unknown as SingleQuestion
                                            ).options?.map((k) => (
                                                <option key={k} value={k ?? ""}>
                                                    {k}
                                                </option>
                                            ))}
                                        </select>
                                    </span>
                                </div>
                            )}
                            {["STATE"].includes(q.singleQuestionType ?? "") && (
                                <div>
                                    <b>Value:</b>
                                    <span className="px-3">
                                        <select
                                            id="value"
                                            name="value"
                                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                            value={props.comp.value ?? ""}
                                            onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                        >
                                            <option value=""></option>
                                            {Object.keys(States).map((k) => (
                                                <option key={k} value={k ?? ""}>
                                                    {States[k]}
                                                </option>
                                            ))}
                                        </select>
                                    </span>
                                </div>
                            )}
                            {["BOOLEAN"].includes(q.singleQuestionType ?? "") && (
                                <div>
                                    <b>Value:</b>
                                    <span className="px-3">
                                        <select
                                            id="value"
                                            name="value"
                                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                            value={props.comp.value ?? ""}
                                            onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                        >
                                            <option value=""></option>
                                            {["Yes", "No"].map((k) => (
                                                <option key={k} value={k ?? ""}>
                                                    {k}
                                                </option>
                                            ))}
                                        </select>
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                    {/* MULTI VALUES */}
                    {["ANSWER_ONE_OF", "ANSWER_NOT_ONE_OF"].includes(props.comp.singleComparisonOperator ?? "") && (
                        <>
                            {!["STATE"].includes(q.singleQuestionType ?? "") && (
                                <div>
                                    <b>Values:</b>
                                    <span className="px-3">
                                        {(
                                            survey_edit.allQuestions[
                                                props.comp.questionId!
                                            ] as unknown as SingleQuestion
                                        ).options?.map((k) => (
                                            <label key={k} className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    className="form-checkbox"
                                                    checked={(props.comp!.values ?? ([] as String[])).includes(k!)}
                                                    value={k!}
                                                    onChange={(e) => toggleOption(e.target.value)}
                                                />
                                                <span className="ml-2">{k}</span>
                                            </label>
                                        ))}
                                    </span>
                                </div>
                            )}
                            {["STATE"].includes(q.singleQuestionType ?? "") && (
                                <div>
                                    <b>Values:</b>
                                    <span className="px-3">
                                        {Object.keys(States).map((k) => (
                                            <label key={k} className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    className="form-checkbox"
                                                    checked={(props.comp!.values ?? ([] as String[])).includes(k!)}
                                                    value={k!}
                                                    onChange={(e) => toggleOption(e.target.value)}
                                                />
                                                <span className="ml-2">{States[k]}</span>
                                            </label>
                                        ))}
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
}

export default SingleComparisonEdit;
