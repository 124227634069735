import Dashboard from "../components/Dashboard";
import Loader from "../components/Loader";

import Admin from "./Admin";
import Agent from "./Agent";
import Lead from "./Lead";
import Survey from "./Survey";
import Preview from "./Preview";
import Match from "./Match";
import Error from "./Error";

import AuthUserStore from "reliance-private-effector-stores/dist/AuthUserStore";
import { useStore } from "effector-react";

import Unauthorized from "./Unauthorized";

import { Route, Switch } from "react-router-dom";

import StickyFooter from "../components/StickyFooter";

function Main() {
    const auth_user_store = useStore(AuthUserStore);
    return (
        <>
            {!auth_user_store.isParsed ? (
                <Loader />
            ) : (
                <>
                    {!auth_user_store.isLoggedIn && <Dashboard />}
                    {auth_user_store.isLoggedIn && !auth_user_store.isAdmin && !auth_user_store.isAgent && (
                        <Unauthorized />
                    )}
                    {auth_user_store.isLoggedIn && auth_user_store.isAdmin && (
                        <>
                            <div className="flex flex-col h-screen">
                                <main className="flex-growp h-screen overflow-hidden">
                                    <Switch>
                                        <Route path="/" component={Dashboard} exact />
                                        <Route path="/preview/:sessionId" component={Preview} />
                                        <Route path="/match/:sourceId" component={Match} />
                                        <Route path="/survey" component={Survey} />
                                        <Route path="/agent" component={Agent} />
                                        <Route path="/lead/:system/:leadId" component={Lead} />
                                        <Route path="/admin" component={Admin} exact />
                                        <Route component={Error} />
                                    </Switch>
                                </main>
                                <StickyFooter />
                            </div>
                        </>
                    )}
                    {auth_user_store.isLoggedIn && auth_user_store.isAgent && !auth_user_store.isAdmin && (
                        <>
                            <div className="flex flex-col h-screen">
                                <main className="flex-growp h-screen">
                                    <Switch>
                                        <Route path="/" component={Dashboard} exact />
                                        <Route path="/match/:sourceId" component={Match} />
                                        <Route path="/survey" component={Survey} />
                                        <Route path="/agent" component={Agent} />
                                        <Route path="/lead/:system/:leadId" component={Lead} />
                                        <Route component={Error} />
                                    </Switch>
                                </main>
                                <StickyFooter />
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default Main;
