import SurveyEditStore, { setEditContext } from "../stores/SurveyEditStore";
import { useStore } from "effector-react";

import * as _ from "lodash";
import { ContextConfiguration } from "reliance-private-survey-schema/dist/API";

const tabs = [
    { name: "Global", token: "global", type: "survey", context: "global" },
    { name: "Source", token: "source", type: "survey", context: "source" },
    // { name: "Firm", token: "firm", type: "survey", context: "firm" },
    { name: "Global DQ", token: "globaldq", type: "dq", context: "global" },
    { name: "Firm DQ", token: "firmdq", type: "dq", context: "firm" },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Example() {
    const survey_edit = useStore(SurveyEditStore);
    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    defaultValue={tabs[0].name}
                >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex" aria-label="Tabs">
                        {tabs?.map((tab) => (
                            <button
                                key={tab.name}
                                className={classNames(
                                    survey_edit.UiMainSection === tab.token
                                        ? "border-indigo-500 text-indigo-600"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    (tab.type === "survey" &&
                                        !_.isEqual(
                                            (survey_edit[`${tab.context}Survey`] ?? ({} as ContextConfiguration))
                                                .linkedQuestions ?? [],
                                            (
                                                survey_edit[`${tab.context}SurveyOriginal`] ??
                                                ({} as ContextConfiguration)
                                            ).linkedQuestions ?? []
                                        )) ||
                                        !_.isEqual(
                                            (survey_edit[`${tab.context}Survey`] ?? ({} as ContextConfiguration))
                                                .behalfOfLabel,
                                            (
                                                survey_edit[`${tab.context}SurveyOriginal`] ??
                                                ({} as ContextConfiguration)
                                            ).behalfOfLabel
                                        )
                                        ? "bg-red-200"
                                        : "",
                                    tab.type === "dq" &&
                                        !_.isEqual(
                                            (survey_edit[`${tab.context}Survey`] ?? ({} as ContextConfiguration))
                                                .disqualifications ?? [],
                                            (
                                                survey_edit[`${tab.context}SurveyOriginal`] ??
                                                ({} as ContextConfiguration)
                                            ).disqualifications ?? []
                                        )
                                        ? "bg-red-200"
                                        : "",
                                    "w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm flex-grow"
                                )}
                                onClick={() => setEditContext({ UiMainSection: tab.token })}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    );
}
