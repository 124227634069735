import SurveyEditStore, { resetAll } from "../stores/SurveyEditStore";
import { useStore } from "effector-react";

import { API, Auth, Hub } from "aws-amplify";

import { useCallback } from "react";

import { v4 as uuidv4 } from "uuid";

import { Link } from "react-router-dom";

function StickyFooter() {
    const survey_edit = useStore(SurveyEditStore);

    const handleSignOutButtonClick = async () => {
        try {
            await Auth.signOut();
            Hub.dispatch("UI Auth", {
                event: "AuthStateChange",
                message: "signedout",
            });
        } catch (error) {
            console.log("error signing out: ", error);
        }
    };

    const submitContextConfigurations = useCallback(async () => {
        try {
            const addSessionBasedContextConfigurationSet = /* GraphQL */ `
                mutation AddSessionBasedContextConfigurationSet($input: SessionBasedContextConfigurationSetInput!) {
                    addSessionBasedContextConfigurationSet(input: $input) {
                        id
                        globalContextConfiguration {
                            id
                        }
                        sourceContextConfiguration {
                            id
                        }
                        firmContextConfiguration {
                            id
                        }
                    }
                }
            `;
            var resultGlobal: any = await API.graphql({
                query: addSessionBasedContextConfigurationSet,
                variables: {
                    input: {
                        id: uuidv4(),
                        globalJson: JSON.stringify(survey_edit.globalSurvey),
                        sourceJson: JSON.stringify(survey_edit.sourceSurvey),
                        firmJson: JSON.stringify(survey_edit.firmSurvey),
                    },
                },
            });
            console.log(resultGlobal);
            window.open(`/preview/${resultGlobal.data.addSessionBasedContextConfigurationSet.id}`, "preview");
        } catch (error) {
            console.log(error);
        }
    }, [survey_edit.globalSurvey, survey_edit.sourceSurvey, survey_edit.firmSurvey]);

    return (
        <div className="bg-gray-800 h-8 px-2 py-1 fixed bottom-0 w-full">
            <Link to="/">
                <button className="bg-white hover:bg-gray-100 text-s text-gray-800 font-semibold py-0 px-0 border border-gray-400 rounded shadow">
                    Home
                </button>
            </Link>
            <button
                className="bg-white hover:bg-gray-100 text-s text-gray-800 font-semibold py-0 px-0 border border-gray-400 rounded shadow"
                onClick={handleSignOutButtonClick}
            >
                Sign out
            </button>
            {survey_edit.isEditing && (
                <>
                    <p className={`inline-block text-white`}>Source: {survey_edit?.source?.name}</p>
                    <p className={`inline-block text-white px-2`}> - </p>
                    <p className={`inline-block text-white`}>Firm: {survey_edit?.firm?.name}</p>
                    <p className={`inline-block text-white px-2`}> - </p>
                    <button
                        className={`bg-white hover:bg-gray-100 text-s text-gray-800 font-semibold py-0 px-0 border border-gray-400 rounded shadow`}
                        onClick={() => resetAll()}
                    >
                        RESET
                    </button>
                    <button
                        className={`mx-3 bg-red-900 hover:bg-red-500 text-s text-white font-semibold py-0 px-0 border border-gray-400 rounded shadow`}
                        onClick={() => submitContextConfigurations()}
                    >
                        PREVIEW
                    </button>
                </>
            )}
            {!survey_edit.isEditing && <p className="text-white"></p>}
        </div>
    );
}
export default StickyFooter;
