import { Question, MultiQuestion, MultiAnswer } from "reliance-private-survey-schema/dist/API";
import SurveyFillStore from "../../stores/SurveyFillStore";
import { States } from "reliance-private-survey-parser/dist/surveyParseTypes";
import { useStore } from "effector-react";

function MultiQuestionBox(props: {
    q: Question;
    handleChangeCallback: (q: string, n: string, v: string | boolean) => void;
}) {
    const q = props.q as unknown as MultiQuestion;
    const survey_fill = useStore(SurveyFillStore);
    const a = survey_fill.answerStorage[q.questionToken!]! as MultiAnswer;
    return (
        <>
            <div>
                {q!.multiQuestionType === "CHECKBOXES" && (
                    <div>
                        {q!.options?.map((v, vIdx) => {
                            return (
                                <div key={vIdx}>
                                    <label className="inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            className="form-radio"
                                            name={`values.${vIdx}#id#${q.questionToken!}`}
                                            value={v!}
                                            checked={Boolean(a.values?.[vIdx] === v)}
                                            onChange={(e) =>
                                                props.handleChangeCallback(
                                                    q.questionToken,
                                                    e.target.name!,
                                                    e.target.checked ? v! : ""
                                                )
                                            }
                                        />
                                        <span className="ml-2">{v}</span>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
                {q!.multiQuestionType === "STATES" && (
                    <div>
                        {Object.keys(States).map((v, vIdx) => {
                            return (
                                <div key={v}>
                                    <label className="inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            className="form-radio"
                                            name={`values.${vIdx}#id#${q.questionToken!}`}
                                            value={v!}
                                            checked={Boolean(a.values?.[vIdx] === v)}
                                            onChange={(e) =>
                                                props.handleChangeCallback(
                                                    q.questionToken,
                                                    e.target.name!,
                                                    e.target.checked ? v! : ""
                                                )
                                            }
                                        />
                                        <span className="ml-2">{States[v]}</span>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
}
export default MultiQuestionBox;
