import SurveyEditStore, { setEditContext, applyOtherFirmOptIn, applyOtherFirmDq } from "../stores/SurveyEditStore";
import { useStore } from "effector-react";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";

import { fragmentContextConfiguration } from "reliance-private-survey-schema/dist/customGraphql/common";
import { ContextConfiguration } from "reliance-private-survey-schema/dist/API";

function DisqualificationExisting() {
    const survey_edit = useStore(SurveyEditStore);
    const [dqFirm, dqFirmSet] = useState("");
    const [optInFirm, optInFirmSet] = useState("");
    useEffect(() => {
        const loadData = async () => {
            if (survey_edit.otherFirmContextConfigurations === undefined) {
                let otherFirmContextConfigurations: Record<string, ContextConfiguration> = {};
                var getMainRecordsBySource = /* GraphQL */ `
                    query getMainRecordsBySource($sourceId: ID!) {
                        getMainRecordsBySource(sourceId: $sourceId) {
                            id
                            sourceId
                            firmId
                            contextConfiguration {
                                ...ContextConfigurationFragment
                            }
                        }
                    }
                `;
                const responseGetMainRecordsBySource: any = await API.graphql({
                    query: getMainRecordsBySource + fragmentContextConfiguration,
                    variables: { sourceId: survey_edit.source?.id },
                });
                if (responseGetMainRecordsBySource.data.getMainRecordsBySource !== null) {
                    for (let q of responseGetMainRecordsBySource.data.getMainRecordsBySource) {
                        otherFirmContextConfigurations[q.firmId] = q.contextConfiguration;
                    }
                }
                setEditContext({
                    otherFirmContextConfigurations: otherFirmContextConfigurations,
                });
            }
        };
        loadData();
    }, [survey_edit.source, survey_edit.firm, survey_edit.otherFirmContextConfigurations]);

    return (
        <>
            <div className="inline-block pr-2 align-top">
                <label htmlFor="existingDq" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Copy DQs from existing:
                </label>
                <div>
                    <select id="existingDq" name="existingDq" onChange={(e) => dqFirmSet(e.target.value)}>
                        <option value=""></option>
                        {Object.keys(survey_edit.otherFirmContextConfigurations ?? {})
                            .filter((e) => !["GLOBAL", survey_edit.firm?.id].includes(e))
                            .map((e: any, index: number) => (
                                <option key={index} value={e}>
                                    #{e} {survey_edit.otherFirmContextConfigurations![e].firm?.name}
                                </option>
                            ))}
                    </select>
                    <button
                        className="bg-green-100 hover:bg-gray-100 text-s text-gray-800 font-semibold py-0 px-0 border border-gray-400 rounded shadow"
                        onClick={() => applyOtherFirmDq(dqFirm)}
                    >
                        Apply DQs
                    </button>
                </div>
            </div>

            <div className="inline-block pr-2 align-top">
                <label htmlFor="sourceId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Copy Opt-Ins from existing:
                </label>
                <div>
                    <select
                        id="existingOptIn"
                        name="existingOptIn"
                        onChange={(e) => {
                            console.log(e.target.value);
                            optInFirmSet(e.target.value);
                        }}
                    >
                        <option value=""></option>
                        {Object.keys(survey_edit.otherFirmContextConfigurations ?? {})
                            .filter((e) => !["GLOBAL", survey_edit.firm?.id].includes(e))
                            .map((e: any, index: number) => (
                                <option key={index} value={e}>
                                    #{e} {survey_edit.otherFirmContextConfigurations![e].firm?.name}
                                </option>
                            ))}
                    </select>
                    <button
                        className="bg-green-100 hover:bg-gray-100 text-s text-gray-800 font-semibold py-0 px-0 border border-gray-400 rounded shadow"
                        onClick={() => applyOtherFirmOptIn(optInFirm)}
                    >
                        Apply Opt-Ins
                    </button>
                </div>
            </div>
        </>
    );
}
export default DisqualificationExisting;
