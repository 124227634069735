import SurveyFillStore from "../../stores/SurveyFillStore";
import { useStore } from "effector-react";

import { Question, PersonQuestion, PersonAnswer } from "reliance-private-survey-schema/dist/API";

function PersonQuestionBox(props: { q: Question; handleChangeCallback: (q: string, n: string, v: string) => void }) {
    const survey_fill = useStore(SurveyFillStore);
    const q = props.q as unknown as PersonQuestion;

    const a = survey_fill.answerStorage[q.questionToken] as unknown as PersonAnswer;

    return (
        <>
            {a && (
                <div>
                    <div>
                        <input
                            type="text"
                            className="form-text"
                            name={`first#id#${q.questionToken!}`}
                            placeholder="First Name"
                            value={a.first ?? ""}
                            onChange={(e) =>
                                props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                            }
                        />
                        <input
                            type="text"
                            className="form-text"
                            name={`middle#id#${q.questionToken!}`}
                            placeholder="Middle Name"
                            value={a.middle ?? ""}
                            onChange={(e) =>
                                props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                            }
                        />
                        <input
                            type="text"
                            className="form-text"
                            name={`last#id#${q.questionToken!}`}
                            placeholder="Last Name"
                            value={a.last ?? ""}
                            onChange={(e) =>
                                props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                            }
                        />
                    </div>
                    {["COMPLETE", "COMPLETE_GENDER"].includes(q!.personQuestionType) && (
                        <>
                            <div>
                                <input
                                    type="email"
                                    className="form-text"
                                    name={`email#id#${q.questionToken!}`}
                                    placeholder="Email"
                                    value={a.email ?? ""}
                                    onChange={(e) =>
                                        props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                    }
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="form-text"
                                    name={`phone#id#${q.questionToken!}`}
                                    placeholder="Phone"
                                    value={a.phone ?? ""}
                                    onChange={(e) =>
                                        props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                    }
                                />
                            </div>
                        </>
                    )}
                    {["SIMPLE_GENDER", "COMPLETE_GENDER"].includes(q!.personQuestionType) && (
                        <>
                            <div>
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio"
                                        name={`gender#id#${q.questionToken!}`}
                                        value="M"
                                        checked={a.gender === "M"}
                                        onChange={(e) =>
                                            props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                        }
                                    />
                                    <span className="ml-2">Male</span>
                                </label>
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio"
                                        name={`gender#id#${q.questionToken!}`}
                                        value="F"
                                        checked={a.gender === "F"}
                                        onChange={(e) =>
                                            props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                        }
                                    />
                                    <span className="ml-2">Female</span>
                                </label>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
}
export default PersonQuestionBox;
