import SurveyFillStore from "../../stores/SurveyFillStore";
import { useStore } from "effector-react";

import { Question, SurveyStatus } from "reliance-private-survey-schema/dist/API";

import SingleQuestionBox from "./SingleQuestionBox";
import AddressQuestionBox from "./AddressQuestionBox";
import PersonQuestionBox from "./PersonQuestionBox";
import MultiQuestionBox from "./MultiQuestionBox";
import DateQuestionBox from ".//DateQuestionBox";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function FillOutQuestionBox(props: {
    q: Question;
    handleChangeCallback: (q: string, n: string, v: string | boolean) => void;
}) {
    const survey_fill = useStore(SurveyFillStore);

    let behalfOf: Record<string, string | null | undefined> = {
        text: props.q.text,
    };

    /// INIT override
    if (
        survey_fill.survey?.status === SurveyStatus.INIT &&
        survey_fill.sourceContextConfiguration?.behalfOfLabel?.textLabel &&
        survey_fill.sourceContextConfiguration?.behalfOfLabel?.textLabel !== ""
    ) {
        behalfOf.text = survey_fill.sourceContextConfiguration?.behalfOfLabel?.textLabel;
    }
    /// MATCHED override
    else if (
        survey_fill.survey?.status === SurveyStatus.MATCHED &&
        survey_fill.survey.behalfOfLabel?.textLabel &&
        survey_fill.survey.behalfOfLabel?.textLabel !== ""
    ) {
        behalfOf.text = survey_fill.survey.behalfOfLabel?.textLabel;
    }

    return (
        <div className="mb-4">
            {props.q && survey_fill.questionStatusStorage[props.q.questionToken].visible && (
                <div className="animate-fade">
                    <div
                        className={classNames(
                            "container border-4 border-dashed border-gray-200 p-2 rounded-lg flex flex-col",
                            survey_fill.questionStatusStorage[props.q.questionToken].error ? "bg-red-100" : ""
                        )}
                    >
                        <div>
                            <h2 className="font-bold pb-2">{props.q!.label}</h2>
                        </div>
                        <div>
                            {/* handle behalf_of special labels */}
                            <div>{props.q.questionToken === "behalf_of" ? behalfOf.text : props.q.text}</div>
                            <div className="pl-2">
                                {props.q?.questionType === "SingleQuestion" && (
                                    <SingleQuestionBox q={props.q} handleChangeCallback={props.handleChangeCallback} />
                                )}
                                {props.q?.questionType === "PersonQuestion" && (
                                    <PersonQuestionBox q={props.q} handleChangeCallback={props.handleChangeCallback} />
                                )}
                                {props.q?.questionType === "AddressQuestion" && (
                                    <AddressQuestionBox q={props.q} handleChangeCallback={props.handleChangeCallback} />
                                )}
                                {props.q?.questionType === "MultiQuestion" && (
                                    <MultiQuestionBox q={props.q} handleChangeCallback={props.handleChangeCallback} />
                                )}
                                {props.q?.questionType === "DateQuestion" && (
                                    <DateQuestionBox q={props.q} handleChangeCallback={props.handleChangeCallback} />
                                )}
                            </div>
                        </div>
                        <div className="text-xs text-right">
                            {JSON.stringify(survey_fill.questionStatusStorage[props.q.questionToken])}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default FillOutQuestionBox;
