import SurveyFillStore from "../../stores/SurveyFillStore";
import { useStore } from "effector-react";

import { Question, AddressQuestion, AddressAnswer } from "reliance-private-survey-schema/dist/API";

import { States } from "reliance-private-survey-parser/dist/surveyParseTypes";

function AddressQuestionBox(props: { q: Question; handleChangeCallback: (q: string, n: string, v: string) => void }) {
    const survey_fill = useStore(SurveyFillStore);

    const q = props.q as unknown as AddressQuestion;
    const a = survey_fill.answerStorage[q.questionToken] as unknown as AddressAnswer;

    return (
        <>
            {a && (
                <div>
                    {q!.addressQuestionType === "STATE" && (
                        <div>
                            <select
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                name={`state#id#${q.questionToken!}`}
                                value={a.state ?? ""}
                                onChange={(e) =>
                                    props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                }
                            >
                                <option value=""></option>
                                {Object.keys(States).map((v) => {
                                    return (
                                        <option key={v} value={v!}>
                                            {States[v]}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}
                    {["NAMED", "NAMED_PHONE"].includes(q!.addressQuestionType) && (
                        <>
                            <div>
                                <input
                                    type="text"
                                    className="form-text"
                                    name={`name#id#${q.questionToken!}`}
                                    placeholder="Name"
                                    value={a.name ?? ""}
                                    onChange={(e) =>
                                        props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                    }
                                />
                            </div>
                        </>
                    )}
                    {["NAMED", "NAMED_PHONE", "UNNAMED", "UNNAMED_PHONE"].includes(q!.addressQuestionType) && (
                        <>
                            <div>
                                <div>
                                    <input
                                        type="text"
                                        className="form-text"
                                        name={`street_1#id#${q.questionToken!}`}
                                        placeholder="Street"
                                        value={a.street_1 ?? ""}
                                        onChange={(e) =>
                                            props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                        }
                                    />
                                    <input
                                        type="text"
                                        className="form-text"
                                        name={`street_2#id#${q.questionToken!}`}
                                        placeholder="Apt, Suite, etc."
                                        value={a.street_2 ?? ""}
                                        onChange={(e) =>
                                            props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                        }
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        className="form-text"
                                        name={`city#id#${q.questionToken!}`}
                                        placeholder="City"
                                        value={a.city ?? ""}
                                        onChange={(e) =>
                                            props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                        }
                                    />
                                    <select
                                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        name={`state#id#${q.questionToken!}`}
                                        value={a.state ?? ""}
                                        onChange={(e) =>
                                            props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                        }
                                    >
                                        <option value=""></option>
                                        {Object.keys(States).map((v) => {
                                            return (
                                                <option key={v} value={v!}>
                                                    {States[v]}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <input
                                        type="text"
                                        className="form-text"
                                        name={`zip#id#${q.questionToken!}`}
                                        placeholder="Zip"
                                        value={a.zip ?? ""}
                                        onChange={(e) =>
                                            props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                        }
                                    />
                                </div>
                                {/* <div>
                                    <input
                                        type="text"
                                        className="form-text"
                                        name={`country#id#${q.questionToken!}`}
                                        placeholder="Country"
                                        value={a.country ?? ""}
                                        onChange={(e) =>
                                            props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                        }
                                    />
                                </div> */}
                            </div>
                        </>
                    )}
                    {["NAMED_PHONE", "UNNAMED_PHONE"].includes(q!.addressQuestionType) && (
                        <>
                            <div>
                                <input
                                    type="text"
                                    className="form-text"
                                    name={`phone#id#${q.questionToken!}`}
                                    placeholder="Phone"
                                    value={a.phone ?? ""}
                                    onChange={(e) =>
                                        props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                    }
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
}
export default AddressQuestionBox;
