import SurveyEditStore, { removeComparison, resetComparison } from "../../stores/SurveyEditStore";
import { useStore } from "effector-react";

import {
    Comparison,
    SingleQuestion,
    PersonQuestion,
    SingleComparison,
    PersonComparison,
    AddressComparison,
    MultiComparison,
    DateComparison,
} from "reliance-private-survey-schema/dist/API";

import SingleComparisonEdit from "./SingleComparisonEdit";
import MultiComparisonEdit from "./MultiComparisonEdit";
import DateComparisonEdit from "./DateComparisonEdit";
import PersonComparisonEdit from "./PersonComparisonEdit";
import AddressComparisonEdit from "./AddressComparisonEdit";
import { QuestionStorage } from "reliance-private-survey-parser/dist/surveyParseTypes";

function ComparisonBox(props: {
    comp:
        | Partial<Comparison>
        | Comparison
        | SingleComparison
        | PersonComparison
        | AddressComparison
        | MultiComparison
        | DateComparison;
    index: number;
    condIndex: number;
}) {
    const comp = props.comp;
    const survey_edit = useStore(SurveyEditStore);

    function getValidTokens(allQuestions: QuestionStorage) {
        return Object.keys(allQuestions).filter((qId, index) => {
            // dont allow to pick yourself (QUESTION)
            if (survey_edit.selectedEditQuestion?.id && qId === survey_edit.selectedEditQuestion?.id) {
                return false;
            }
            // dont allow to pick a question not in correct context (QUESTION)
            if (
                survey_edit.selectedEditQuestion?.id &&
                survey_edit.selectedEditQuestion?.context === "GLOBAL" &&
                survey_edit.allQuestions![qId]!.sourceId !==
                    survey_edit.selectedEditQuestionStorage[survey_edit.selectedEditQuestion!.id!].sourceId
            ) {
                return false;
            }
            // dont allow to pick a question not in correct context (DISQUALIFICATION)
            if (
                survey_edit.selectedEditDisqualification?.id &&
                survey_edit.selectedEditDisqualification!.context.toLowerCase() === "global" &&
                survey_edit.allQuestions[qId].sourceId!.toLowerCase() !== "global"
            ) {
                return false;
            }
            // dont allow to pick a question that is not currently linked
            if (
                !survey_edit.globalSurveyQuestionIds.includes(qId) &&
                !survey_edit.sourceSurveyQuestionIds.includes(qId) &&
                !survey_edit.firmSurveyQuestionIds.includes(qId)
            ) {
                return false;
            }

            // dont allow picking a SingleQuestion of type (TEXT/TEXTAREA)
            if (
                survey_edit.allQuestions![qId]!.questionType === "SingleQuestion" &&
                ["TEXT", "TEXTAREA"].includes(
                    (survey_edit.allQuestions![qId]! as unknown as SingleQuestion).singleQuestionType ?? ""
                )
            ) {
                return false;
            }

            // dont allow picking a PersonQuestion of type (SIMPLE)
            if (
                survey_edit.allQuestions![qId]!.questionType === "PersonQuestion" &&
                ["SIMPLE"].includes(
                    (survey_edit.allQuestions![qId]! as unknown as PersonQuestion).personQuestionType ?? ""
                )
            ) {
                return false;
            }

            /// All ADDRESSS questions are good for now, as they are all contain STATE

            return true;
        });
    }

    return (
        <>
            <div className="border-4 border-dashed border-gray-200 rounded-lg mb-4 flex flex-col">
                <div>
                    Comparison #{props.index + 1}
                    <button
                        type="button"
                        onClick={() => removeComparison({ index: props.index, condIndex: props.condIndex })}
                    >
                        X
                    </button>
                </div>
                <div>
                    <b>Question ID:</b>
                    <span className="px-3">
                        <select
                            id="questionId"
                            name="questionId"
                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            value={comp.questionId}
                            onChange={(e) =>
                                resetComparison({ qId: e.target.value, index: props.index, condIndex: props.condIndex })
                            }
                        >
                            <option value=""></option>
                            <optgroup label="Global Questions">
                                {getValidTokens(survey_edit.allQuestions)
                                    .filter((qId, index) => qId.endsWith("#GLOBAL"))
                                    .sort()
                                    .map((qId, index) => (
                                        <option key={index} value={qId}>
                                            {qId.split("#")[0]}
                                        </option>
                                    ))}
                            </optgroup>
                            <optgroup label="Source Questions">
                                {getValidTokens(survey_edit.allQuestions)
                                    .filter((qId, index) => !qId.endsWith("#GLOBAL"))
                                    .sort()
                                    .map((qId, index) => (
                                        <option key={index} value={qId}>
                                            {qId.split("#")[0]}
                                        </option>
                                    ))}
                            </optgroup>
                        </select>
                    </span>
                </div>
                {comp.comparisonType === "PersonComparison" && (
                    <PersonComparisonEdit
                        comp={props.comp as PersonComparison}
                        index={props.index}
                        condIndex={props.condIndex}
                    />
                )}
                {comp.comparisonType === "AddressComparison" && (
                    <AddressComparisonEdit
                        comp={props.comp as AddressComparison}
                        index={props.index}
                        condIndex={props.condIndex}
                    />
                )}
                {comp.comparisonType === "SingleComparison" && (
                    <SingleComparisonEdit
                        comp={props.comp as SingleComparison}
                        index={props.index}
                        condIndex={props.condIndex}
                    />
                )}
                {comp.comparisonType === "MultiComparison" && (
                    <MultiComparisonEdit
                        comp={props.comp as MultiComparison}
                        index={props.index}
                        condIndex={props.condIndex}
                    />
                )}
                {comp.comparisonType === "DateComparison" && (
                    <DateComparisonEdit
                        comp={props.comp as DateComparison}
                        index={props.index}
                        condIndex={props.condIndex}
                    />
                )}
            </div>
        </>
    );
}

export default ComparisonBox;
