import { modifyStorageQuestionDetails, ModifyQuestionDetails } from "../../stores/SurveyEditStore";

import { useCallback } from "react";

import { Question, AddressQuestion, AddressQuestionType } from "reliance-private-survey-schema/dist/API";

function AddressQuestionEdit(props: { q: Question }) {
    var q = props.q as unknown as AddressQuestion;

    const handleChange = useCallback(
        (fieldName: string, fieldValue: string | boolean) => {
            var payload: ModifyQuestionDetails = {
                id: q.id!,
                fieldName: fieldName,
                fieldValue: fieldValue,
            };
            if (fieldName.includes(".")) {
                const x = fieldName.split(".");
                const newList = (q[x[0]] ?? []) as string[];
                newList[x[1]] = fieldValue;
                payload.fieldName = x[0];
                payload.fieldValue = newList;
                modifyStorageQuestionDetails(payload);
            } else {
                modifyStorageQuestionDetails(payload);
            }
        },
        [q]
    );

    return (
        <form>
            <div>
                <b>Address Question Type:</b>
                <span className="px-3">
                    <select
                        id="addressQuestionType"
                        name="addressQuestionType"
                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        value={q?.addressQuestionType ?? ""}
                        onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                    >
                        <option value=""></option>
                        {Object.keys(AddressQuestionType).map((k) => (
                            <option key={k} value={k}>
                                {k}
                            </option>
                        ))}
                    </select>
                </span>
            </div>

            <div>
                <b>Use AutoComplete API:</b>
                <span className="px-3">
                    <input
                        type="checkbox"
                        className="form-radio"
                        name="autoComplete"
                        value="Yes"
                        checked={Boolean(q.autoComplete)}
                        onChange={(e) => handleChange(e.target.name!, e.target.checked)}
                    />
                </span>
            </div>
        </form>
    );
}
export default AddressQuestionEdit;
