import * as _ from "lodash";
import { createEvent, createStore } from "effector-logger";
import {
    ContextConfiguration,
    Firm,
    Source,
    Question,
    Disqualification,
    Condition,
    Comparison,
    SingleComparison,
    PersonComparison,
    AddressComparison,
    MultiComparison,
    DateComparison,
    LinkedQuestion,
    BehalfOfLabel,
} from "reliance-private-survey-schema/dist/API";

import { v4 as uuidv4 } from "uuid";

export type QuestionStorage = Record<string, Question>;
export type DisqualificationStorage = Record<string, Disqualification>;

export type SelectedEditQuestionDetails = {
    id: string;
    context: string;
};

export type SelectedEditDisqualificationDetails = {
    id: string;
    context: string;
};

export type OptInChangeDetails = {
    id: string;
    checked?: boolean;
};

// Standard type
export type SurveyEdit = {
    isEditing: boolean;

    firm?: Firm;
    source?: Source;

    UiMainSection: string;
    UiSideSection: string;

    globalSurveyOriginal?: ContextConfiguration;
    globalSurvey?: ContextConfiguration;
    globalSurveyQuestionIds: string[];

    sourceSurveyOriginal?: ContextConfiguration;
    sourceSurvey?: ContextConfiguration;
    sourceSurveyQuestionIds: string[];

    firmSurveyOriginal?: ContextConfiguration;
    firmSurvey?: ContextConfiguration;
    firmSurveyQuestionIds: string[];

    allQuestionsOriginal: QuestionStorage;
    allQuestions: QuestionStorage;

    allDisqualificationsOriginal: DisqualificationStorage;

    selectedEditQuestion?: SelectedEditQuestionDetails;
    selectedEditQuestionStorage: QuestionStorage;

    selectedEditDisqualification?: SelectedEditDisqualificationDetails;
    selectedEditDisqualificationStorage: DisqualificationStorage;

    otherFirmContextConfigurations?: Record<string, ContextConfiguration>;
};

const emptySurvey: SurveyEdit = {
    isEditing: false,

    UiMainSection: "global",
    UiSideSection: "edit",

    globalSurveyQuestionIds: [],
    sourceSurveyQuestionIds: [],
    firmSurveyQuestionIds: [],

    globalSurveyOriginal: undefined,
    globalSurvey: undefined,

    sourceSurveyOriginal: undefined,
    sourceSurvey: undefined,

    firmSurveyOriginal: undefined,
    firmSurvey: undefined,

    selectedEditQuestionStorage: {},
    selectedEditDisqualificationStorage: {},

    allQuestionsOriginal: {},
    allQuestions: {},

    allDisqualificationsOriginal: {},

    selectedEditQuestion: undefined,
    selectedEditDisqualification: undefined,

    otherFirmContextConfigurations: undefined,
};

export type ModifyQuestionDetails = {
    id: string;
    fieldName: string;
    fieldValue: boolean | string | string[];
};

export type ModifyDisqualificationDetails = {
    id: string;
    fieldName: string;
    fieldValue: boolean | string | string[];
};

export type ModifyBehalfOfLabelDetails = {
    name: string;
    value: string;
};

export type ModifyComparisonDetails = {
    comp: SingleComparison | MultiComparison | DateComparison | PersonComparison | AddressComparison;
    index: number;
    condIndex: number;
    fieldName: string;
    fieldValue: boolean | string | string[];
};

export type ModifyComparisonValuesDetails = {
    comp: SingleComparison | MultiComparison | DateComparison | PersonComparison | AddressComparison;
    index: number;
    condIndex: number;
    type: string;
    value: string;
};

export type RemoveComparision = {
    index: number;
    condIndex: number;
};

export type ResetComparision = {
    qId: string;
    index: number;
    condIndex: number;
};

export type NewQuestionInputs = {
    questionToken: string;
    questionType: string;
};

export type NewDisqualificationInputs = {
    id: string;
    name: string;
};

export type SetContextConfiguration = {
    survey: string;
    contextConfiguration: ContextConfiguration;
};

export type PartialSurveyEdit = Partial<SurveyEdit>;

export const setEditContext = createEvent<PartialSurveyEdit>();
export const setContextConfiguration = createEvent<SetContextConfiguration>();
export const setQuestions = createEvent<Array<Question[]>>();
export const setDisqualifications = createEvent();

export const setEditingQuestion = createEvent<SelectedEditQuestionDetails>();
export const resetEditingQuestion = createEvent();
export const saveEditingQuestion = createEvent();

export const setEditingDisqualification = createEvent<SelectedEditDisqualificationDetails>();
export const resetEditingDisqualification = createEvent();
export const saveEditingDisqualification = createEvent();

export const removeDisqualification = createEvent();
export const addDisqualification = createEvent<NewDisqualificationInputs>();

export const removeQuestion = createEvent();
export const addQuestion = createEvent<NewQuestionInputs>();

export const modifyStorageQuestionDetails = createEvent<ModifyQuestionDetails>();
export const modifyStorageDisqualificationDetails = createEvent<ModifyDisqualificationDetails>();

export const modifyComparisonDetails = createEvent<ModifyComparisonDetails>();
export const modifyComparisonValues = createEvent<ModifyComparisonValuesDetails>();

export const modifyBehalfOfLabelChange = createEvent<ModifyBehalfOfLabelDetails>();

export const removeCondition = createEvent<number>();
export const addCondition = createEvent();

export const removeComparison = createEvent<RemoveComparision>();
export const addComparison = createEvent<number>();
export const resetComparison = createEvent<ResetComparision>();

export const addExistingQuestion = createEvent<string>();

export const handleOptInChange = createEvent<OptInChangeDetails>();

export const applyOtherFirmDq = createEvent<string>();
export const applyOtherFirmOptIn = createEvent<string>();

export const resetAll = createEvent();

//// FUNCTIONS
const doSetEditingQuestion = (s: SurveyEdit, p: SelectedEditQuestionDetails): SurveyEdit => {
    /// see if the question exists, and in storage or not
    s.selectedEditQuestion = p;
    s.UiSideSection = "edit";
    s.selectedEditDisqualification = undefined;
    if (!s.selectedEditQuestionStorage[p.id]) {
        if (s.allQuestionsOriginal[p.id]) {
            s.selectedEditQuestionStorage[p.id] = _.cloneDeep(s.allQuestionsOriginal[p.id]);
        }
    }
    return s;
};
const doSetEditingDisqualification = (s: SurveyEdit, p: SelectedEditDisqualificationDetails): SurveyEdit => {
    /// see if the Disqualifications exists, and in storage or not
    s.selectedEditQuestion = undefined;
    s.selectedEditDisqualification = p;
    if (!s.selectedEditDisqualificationStorage[p.id]) {
        s.selectedEditDisqualificationStorage[p.id] = _.cloneDeep(s.allDisqualificationsOriginal[p.id]);
    }
    return s;
};

//// STORE
export default createStore<SurveyEdit>(emptySurvey)
    .on(setEditContext, (state, se) => {
        return {
            ...state,
            ...se,
        };
    })

    .on(setContextConfiguration, (state, payload) => {
        /// set the configurations
        state[`${payload.survey}SurveyOriginal`] = _.cloneDeep(payload.contextConfiguration);
        state[`${payload.survey}Survey`] = _.cloneDeep(payload.contextConfiguration);

        /// parse out the question Ids
        state[`${payload.survey}SurveyQuestionIds`] = (
            payload.contextConfiguration.linkedQuestions ?? ([] as LinkedQuestion[])
        ).reduce((a, lq) => {
            a.push(lq!.questionId!);
            return a;
        }, [] as string[]);

        return {
            ...state,
        };
    })

    .on(setQuestions, (state, questions) => {
        var newStorage: QuestionStorage = {};

        /// flip questions from array into dict with ID as key
        questions.forEach((qOuter) => {
            qOuter.forEach((q) => {
                newStorage[q.id!] = q;
            });
        });

        state.allQuestionsOriginal = _.cloneDeep(newStorage);
        state.allQuestions = _.cloneDeep(newStorage);

        return {
            ...state,
        };
    })

    .on(setDisqualifications, (state) => {
        var newStorage: DisqualificationStorage = {};

        /// loop thru all of the disqualifications linked in the original surveys, this should only be called on the init
        if (state.globalSurveyOriginal?.disqualifications) {
            state.globalSurveyOriginal?.disqualifications?.forEach((dq) => {
                newStorage[dq!.id!] = dq!;
            });
        }
        if (state.firmSurveyOriginal?.disqualifications) {
            state.firmSurveyOriginal?.disqualifications?.forEach((dq) => {
                newStorage[dq!.id!] = dq!;
            });
        }

        state.allDisqualificationsOriginal = _.cloneDeep(newStorage);

        return {
            ...state,
        };
    })

    .on(handleOptInChange, (state, payload) => {
        if (!state.firmSurvey!.optInQuestions) {
            state.firmSurvey!.optInQuestions = [];
        }

        let index = state.firmSurvey!.optInQuestions!.indexOf(payload.id);

        if (payload.checked) {
            if (index === -1) {
                state.firmSurvey!.optInQuestions!.push(payload.id);
            }
        } else if (index >= 0) {
            state.firmSurvey!.optInQuestions!.splice(index, 1);
        }

        return {
            ...state,
        };
    })

    .on(modifyBehalfOfLabelChange, (state, payload) => {
        if (!state.sourceSurvey!.behalfOfLabel) {
            state.sourceSurvey!.behalfOfLabel = {} as BehalfOfLabel;
        }
        state.sourceSurvey!.behalfOfLabel[payload.name!] = payload.value!;
        return {
            ...state,
        };
    })

    .on(modifyStorageQuestionDetails, (state, payload) => {
        state.selectedEditQuestionStorage[payload.id][payload.fieldName] = payload.fieldValue;
        return {
            ...state,
        };
    })
    .on(modifyStorageDisqualificationDetails, (state, payload) => {
        state.selectedEditDisqualificationStorage[payload.id][payload.fieldName] = payload.fieldValue;
        return {
            ...state,
        };
    })

    .on(modifyComparisonDetails, (state, payload) => {
        payload.comp[payload.fieldName] = payload.fieldValue;

        if (state.selectedEditQuestion?.id) {
            state.selectedEditQuestionStorage![state.selectedEditQuestion!.id]!.conditions![
                payload.condIndex
            ]!.comparisons![payload.index]! = payload.comp;
        }

        if (state.selectedEditDisqualification?.id) {
            state.selectedEditDisqualificationStorage![state.selectedEditDisqualification!.id]!.conditions![
                payload.condIndex
            ]!.comparisons![payload.index]! = payload.comp;
        }

        return {
            ...state,
        };
    })
    .on(modifyComparisonValues, (state, payload) => {
        var comp:
            | SingleComparison
            | MultiComparison
            | DateComparison
            | PersonComparison
            | AddressComparison
            | undefined;
        switch (payload.type) {
            case "AddressComparison":
                comp = payload.comp as unknown as AddressComparison;
                if (comp.values?.includes(payload.value)) {
                    let index = comp.values.indexOf(payload.value);
                    if (index !== -1) {
                        comp.values.splice(index, 1);
                    }
                } else {
                    if (!comp.values) {
                        comp.values = [];
                    }
                    comp.values?.push(payload.value);
                }
                break;
            case "PersonComparison":
                comp = payload.comp as unknown as PersonComparison;
                if (comp.values?.includes(payload.value)) {
                    let index = comp.values.indexOf(payload.value);
                    if (index !== -1) {
                        comp.values.splice(index, 1);
                    }
                } else {
                    if (!comp.values) {
                        comp.values = [];
                    }
                    comp.values?.push(payload.value);
                }
                break;
            case "SingleComparison":
                comp = payload.comp as unknown as SingleComparison;
                if (comp.values?.includes(payload.value)) {
                    let index = comp.values.indexOf(payload.value);
                    if (index !== -1) {
                        comp.values.splice(index, 1);
                    }
                } else {
                    if (!comp.values) {
                        comp.values = [];
                    }
                    comp.values?.push(payload.value);
                }
                break;
            case "MultiComparison":
                comp = payload.comp as unknown as MultiComparison;
                if (comp.values?.includes(payload.value)) {
                    var indexM = comp.values.indexOf(payload.value);
                    if (indexM !== -1) {
                        comp.values.splice(indexM, 1);
                    }
                } else {
                    if (!comp.values) {
                        comp.values = [];
                    }
                    comp.values?.push(payload.value);
                }
                break;
        }

        if (comp) {
            if (state.selectedEditQuestion?.id) {
                state.selectedEditQuestionStorage![state.selectedEditQuestion!.id]!.conditions![
                    payload.condIndex
                ]!.comparisons![payload.index]! = comp;
            } else {
                state.selectedEditDisqualificationStorage![state.selectedEditDisqualification!.id]!.conditions![
                    payload.condIndex
                ]!.comparisons![payload.index]! = comp;
            }
        }

        return {
            ...state,
        };
    })

    .on(addComparison, (state, index) => {
        var record = state.selectedEditQuestion?.id
            ? state.selectedEditQuestionStorage[state.selectedEditQuestion!.id]
            : state.selectedEditDisqualificationStorage[state.selectedEditDisqualification!.id];

        var cond = record.conditions![index];

        if (!cond?.comparisons) {
            cond!.comparisons = [] as Comparison[];
        }
        var newComp: Partial<Comparison> = {
            __typename: "Comparison",
            id: uuidv4(),
        };
        cond!.comparisons.push(newComp);
        return {
            ...state,
        };
    })
    .on(removeComparison, (state, payload) => {
        var record = state.selectedEditQuestion?.id
            ? state.selectedEditQuestionStorage[state.selectedEditQuestion!.id]
            : state.selectedEditDisqualificationStorage[state.selectedEditDisqualification!.id];
        var conds = record.conditions ?? ([] as Condition[]);
        conds[payload.condIndex]?.comparisons?.splice(payload.index, 1);
        return {
            ...state,
        };
    })
    .on(resetComparison, (state, payload) => {
        var sourceQuestionType = state.allQuestions[payload.qId].questionType;

        var comparisonQuestionBaseType = sourceQuestionType!.replace("Question", "");

        var newComparison: Comparison = {
            __typename: "Comparison",
            id: uuidv4(),
            questionId: payload.qId,
            comparisonType: `${comparisonQuestionBaseType}Comparison`,
        };
        if (state.selectedEditQuestion?.id) {
            var q = state.selectedEditQuestionStorage[state.selectedEditQuestion!.id];
            q.conditions![payload.condIndex]!.comparisons![payload.index]! = newComparison;
        } else if (state.selectedEditDisqualification?.id) {
            var dq = state.selectedEditDisqualificationStorage[state.selectedEditDisqualification!.id];
            dq.conditions![payload.condIndex]!.comparisons![payload.index]! = newComparison;
        }

        return {
            ...state,
        };
    })
    .on(removeCondition, (state, index) => {
        var record = state.selectedEditQuestion?.id
            ? state.selectedEditQuestionStorage[state.selectedEditQuestion!.id]
            : state.selectedEditDisqualificationStorage[state.selectedEditDisqualification!.id];
        record.conditions?.splice(index, 1);
        return {
            ...state,
        };
    })
    .on(addCondition, (state) => {
        var record = state.selectedEditQuestion?.id
            ? state.selectedEditQuestionStorage[state.selectedEditQuestion!.id]
            : state.selectedEditDisqualificationStorage[state.selectedEditDisqualification!.id];
        var newCond: Condition = {
            __typename: "Condition",
            id: uuidv4(),
            name: "NewCondition",
            comparisons: undefined,
            version: record.version,
        };
        if (!record.conditions) {
            record.conditions = [] as Condition[];
        }
        record.conditions?.push(newCond);
        return {
            ...state,
        };
    })

    .on(saveEditingQuestion, (state) => {
        /// take the stored question, and commit it to the various local arrays
        var newQ = state.selectedEditQuestionStorage[state.selectedEditQuestion!.id];
        console.log(state);
        if (state[`${state.selectedEditQuestion!.context}Survey`]?.linkedQuestions) {
            var lqs = state[`${state.selectedEditQuestion!.context}Survey`].linkedQuestions;
            for (let lqIdx in lqs) {
                if (lqs[lqIdx]!.questionId === state.selectedEditQuestion!.id) {
                    state[`${state.selectedEditQuestion!.context}Survey`].linkedQuestions[lqIdx]!.question =
                        _.cloneDeep(newQ);
                    state.allQuestions[state.selectedEditQuestion!.id] = _.cloneDeep(newQ);
                }
            }
        }

        return {
            ...state,
        };
    })
    .on(setEditingQuestion, (state, payload) => {
        state = doSetEditingQuestion(state, payload);
        return {
            ...state,
        };
    })
    .on(resetEditingQuestion, (state) => {
        /// see if the question exists, and in storage or not
        if (state.allQuestionsOriginal[state.selectedEditQuestion!.id]) {
            state.selectedEditQuestionStorage[state.selectedEditQuestion!.id] = _.cloneDeep(
                state.allQuestionsOriginal[state.selectedEditQuestion!.id]
            );
        }
        return {
            ...state,
        };
    })

    .on(saveEditingDisqualification, (state) => {
        var newDQ = state.selectedEditDisqualificationStorage[state.selectedEditDisqualification!.id];
        if (state[`${state.selectedEditDisqualification!.context}Survey`]?.disqualifications) {
            var dqs = state[`${state.selectedEditDisqualification!.context}Survey`].disqualifications;
            for (let dqIdx in dqs) {
                if (dqs[dqIdx]!.id === state.selectedEditDisqualification!.id) {
                    state[`${state.selectedEditDisqualification!.context}Survey`].disqualifications[dqIdx] =
                        _.cloneDeep(newDQ);
                }
            }
        }

        return {
            ...state,
        };
    })
    .on(setEditingDisqualification, (state, payload) => {
        state = doSetEditingDisqualification(state, payload);
        return {
            ...state,
        };
    })
    .on(resetEditingDisqualification, (state) => {
        /// see if the Disqualification exists, and in storage or not
        if (state.allDisqualificationsOriginal[state.selectedEditDisqualification!.id]) {
            state.selectedEditDisqualificationStorage[state.selectedEditDisqualification!.id] = _.cloneDeep(
                state.allDisqualificationsOriginal[state.selectedEditDisqualification!.id]
            );
        }
        return {
            ...state,
        };
    })

    .on(removeQuestion, (state) => {
        /// remove selected edit question from the editing questions survey context
        var cc = state[`${state.selectedEditQuestion!.context}Survey`] as ContextConfiguration;
        cc.linkedQuestions?.map((lq, index) => {
            if (lq?.questionId === state.selectedEditQuestion?.id) {
                cc.linkedQuestions!.splice(index, 1);
            }
            return true;
        });

        // refresh IDs in this survey
        state[`${state.selectedEditQuestion!.context}SurveyQuestionIds`] = (
            cc.linkedQuestions ?? ([] as LinkedQuestion[])
        ).reduce((a, lq) => {
            a.push(lq!.questionId!);
            return a;
        }, [] as string[]);

        // remove the question from the allQuestions
        delete state.allQuestions[state.selectedEditQuestion!.id];
        delete state.selectedEditQuestionStorage[state.selectedEditQuestion!.id];

        state.selectedEditQuestion = undefined;

        return {
            ...state,
        };
    })
    .on(addQuestion, (state, payload) => {
        /// add a new question to the current survey
        var editingSurvey = state.UiMainSection;
        var cc = state[`${editingSurvey}Survey`] as ContextConfiguration;

        if (!cc.linkedQuestions) {
            cc.linkedQuestions = [] as LinkedQuestion[];
        }

        // translate ID to LABEL
        const words = payload.questionToken.split("_");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }

        var parsedSourceId = editingSurvey === "global" ? "GLOBAL" : state.source!.id!;

        var newQ: Question = {
            __typename: "Question",
            questionType: payload.questionType,
            id: `${payload.questionToken}#${parsedSourceId}`,
            questionToken: payload.questionToken,
            optIn: null,
            sourceId: editingSurvey === "global" ? "GLOBAL" : state.source!.id!,
            label: words.join(" "),
            text: "EDITME",
            version: uuidv4(),
        };

        /// add to storage
        state.allQuestionsOriginal[newQ.id] = _.cloneDeep(newQ);

        /// link it
        var lQ: LinkedQuestion = {
            __typename: "LinkedQuestion",
            id: uuidv4(),
            questionId: newQ.id,
            question: newQ,
            sort: 0,
        };

        cc.linkedQuestions!.push(lQ);

        // refresh IDs in this survey
        state[`${editingSurvey}SurveyQuestionIds`] = (cc.linkedQuestions ?? ([] as LinkedQuestion[])).reduce(
            (a, lq) => {
                a.push(lq!.questionId!);
                return a;
            },
            [] as string[]
        );

        /// set it automatically on add as the editing question
        state = doSetEditingQuestion(state, { id: newQ.id, context: editingSurvey });

        return {
            ...state,
        };
    })
    .on(addExistingQuestion, (state, newQID) => {
        /// add an existing question to the current survey
        var editingSurvey = state.UiMainSection;

        if (!state[`${editingSurvey}SurveyQuestionIds`].includes(newQID)) {
            var cc = state[`${editingSurvey}Survey`] as ContextConfiguration;

            if (!cc.linkedQuestions) {
                cc.linkedQuestions = [] as LinkedQuestion[];
            }

            /// link it
            var lQ: LinkedQuestion = {
                __typename: "LinkedQuestion",
                id: uuidv4(),
                questionId: newQID,
                question: _.cloneDeep(state.allQuestions[newQID]),
                sort: 0,
            };

            cc.linkedQuestions!.push(lQ);

            // refresh IDs in this survey
            state[`${editingSurvey}SurveyQuestionIds`] = (cc.linkedQuestions ?? ([] as LinkedQuestion[])).reduce(
                (a, lq) => {
                    a.push(lq!.questionId!);
                    return a;
                },
                [] as string[]
            );

            /// set it automatically on add as the editing question
            state = doSetEditingQuestion(state, { id: newQID, context: editingSurvey });
        }

        return {
            ...state,
        };
    })

    .on(removeDisqualification, (state) => {
        /// remove selected edit question from the editing questions survey context
        var cc = state[`${state.selectedEditDisqualification!.context}Survey`] as ContextConfiguration;
        cc.disqualifications?.map((dq, index) => {
            if (dq?.id === state.selectedEditDisqualification?.id) {
                cc.disqualifications!.splice(index, 1);
            }
            return true;
        });

        // remove the question from the allQuestions
        delete state.selectedEditDisqualificationStorage[state.selectedEditDisqualification!.id];

        return {
            ...state,
        };
    })
    .on(addDisqualification, (state, payload) => {
        /// add a new question to the current survey
        var editingSurvey = state.UiMainSection;
        editingSurvey = editingSurvey.replace("dq", "");
        var cc = state[`${editingSurvey}Survey`] as ContextConfiguration;

        if (!cc.disqualifications) {
            cc.disqualifications = [] as Disqualification[];
        }

        var newDq: Disqualification = {
            __typename: "Disqualification",
            id: payload.id,
            name: payload.name,
            version: uuidv4(),
            sourceId: cc.sourceId,
        };

        cc.disqualifications!.push(newDq);

        /// add to storage
        state.allDisqualificationsOriginal[payload.id] = _.cloneDeep(newDq);

        return {
            ...state,
        };
    })

    .on(applyOtherFirmDq, (state, firmId) => {
        let otherDqs = _.cloneDeep(
            (state.otherFirmContextConfigurations![firmId] as ContextConfiguration).disqualifications ?? []
        );

        for (let dq of otherDqs) {
            let new_version = uuidv4();
            let new_id = uuidv4();

            /// set the top levels
            dq!.id = new_id;
            dq!.version = new_version;
            dq!.name = `${dq!.name} (Copy #${firmId})`;

            /// go through and set the low levels
            dq!.conditions?.forEach((cond) => {
                cond?.comparisons?.forEach((comp) => {
                    comp!.id = uuidv4();
                });
                cond!.id = uuidv4();
                cond!.version = new_version;
            });

            // console.log(dq);

            if (!state.firmSurvey?.disqualifications) {
                state.firmSurvey!.disqualifications = [];
            }

            /// add to survey
            state.firmSurvey?.disqualifications?.push(dq);

            /// add to storage
            state.allDisqualificationsOriginal[dq!.id] = _.cloneDeep(dq!);
        }

        return {
            ...state,
        };
    })

    .on(applyOtherFirmOptIn, (state, firmId) => {
        let otherOptIns = (state.otherFirmContextConfigurations![firmId] as ContextConfiguration).optInQuestions;

        // console.log(otherOptIns);

        if (!state.firmSurvey?.optInQuestions) {
            state.firmSurvey!.optInQuestions = [];
        }

        for (let i of otherOptIns ?? []) {
            if (state.firmSurvey?.optInQuestions && !state.firmSurvey.optInQuestions.includes(i)) {
                state.firmSurvey.optInQuestions.push(i);
            }
        }

        return {
            ...state,
        };
    })

    .on(resetAll, (state) => emptySurvey);
