import SurveyFillStore from "../../stores/SurveyFillStore";
import { useStore } from "effector-react";

import { Question, SingleAnswer, SingleQuestion, SurveyStatus } from "reliance-private-survey-schema/dist/API";

function SingleQuestionBox(props: { q: Question; handleChangeCallback: (q: string, n: string, v: string) => void }) {
    const survey_fill = useStore(SurveyFillStore);
    const q = props.q as unknown as SingleQuestion;

    const a = survey_fill.answerStorage[q.questionToken] as unknown as SingleAnswer;

    let behalfOf: Record<string, string | null | undefined> | undefined = undefined;

    /// INIT override
    if (q.questionToken === "behalf_of") {
        if (
            survey_fill.survey?.status === SurveyStatus.INIT &&
            survey_fill.sourceContextConfiguration?.behalfOfLabel?.textLabel &&
            survey_fill.sourceContextConfiguration?.behalfOfLabel?.textLabel !== ""
        ) {
            behalfOf = survey_fill.sourceContextConfiguration?.behalfOfLabel!;
        }
        /// MATCHED override
        if (
            survey_fill.survey?.status === SurveyStatus.MATCHED &&
            survey_fill.survey.behalfOfLabel?.textLabel &&
            survey_fill.survey.behalfOfLabel?.textLabel !== ""
        ) {
            behalfOf = survey_fill.survey.behalfOfLabel!;
        }
    }

    return (
        <>
            {a && (
                <div>
                    {q!.singleQuestionType === "TEXT" && (
                        <div>
                            <input
                                type="text"
                                className="form-text"
                                name={`value#id#${q.questionToken!}`}
                                value={a.value ?? ""}
                                onChange={(e) =>
                                    props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                }
                            />
                        </div>
                    )}
                    {q!.singleQuestionType === "TEXTAREA" && (
                        <div>
                            <textarea
                                className="form-textarea mt-1 block w-full"
                                rows={5}
                                name={`value#id#${q.questionToken!}`}
                                value={a.value ?? ""}
                                onChange={(e) =>
                                    props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                }
                            />
                        </div>
                    )}
                    {q!.singleQuestionType === "SELECT" && (
                        <div>
                            <select
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                name={`value#id#${q.questionToken!}`}
                                value={a.value ?? ""}
                                onChange={(e) =>
                                    props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                }
                            >
                                <option value=""></option>
                                {q!.options?.map((v) => {
                                    return (
                                        <option key={v} value={v!}>
                                            {v}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}
                    {q!.singleQuestionType === "BOOLEAN" && (
                        <div>
                            <div>
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio"
                                        name={`value#id#${q.questionToken!}`}
                                        value="Yes"
                                        checked={a.value === "Yes"}
                                        onChange={(e) =>
                                            props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                        }
                                    />
                                    <span className="ml-2">Yes</span>
                                </label>
                            </div>
                            <div>
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio"
                                        name={`value#id#${q.questionToken!}`}
                                        value="No"
                                        checked={a.value === "No"}
                                        onChange={(e) =>
                                            props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                                        }
                                    />
                                    <span className="ml-2">No</span>
                                </label>
                            </div>
                        </div>
                    )}
                    {q!.singleQuestionType === "RADIO" && (
                        <div>
                            {q!.options?.map((v) => {
                                return (
                                    <div key={v!}>
                                        <label className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                className="form-radio"
                                                name={`value#id#${q.questionToken!}`}
                                                value={v!}
                                                checked={a.value === v!}
                                                onChange={(e) =>
                                                    props.handleChangeCallback(
                                                        q.questionToken,
                                                        e.target.name!,
                                                        e.target.value!
                                                    )
                                                }
                                            />
                                            <span className="ml-2">
                                                {q!.questionToken === "behalf_of" &&
                                                    behalfOf &&
                                                    v === "Self" &&
                                                    behalfOf.selfLabel}
                                                {q!.questionToken === "behalf_of" &&
                                                    behalfOf &&
                                                    v !== "Self" &&
                                                    behalfOf.otherLabel}
                                                {(q!.questionToken !== "behalf_of" || !behalfOf) && v}
                                            </span>
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
export default SingleQuestionBox;
