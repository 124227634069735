import SurveyEditStore, {
    modifyComparisonDetails,
    modifyComparisonValues,
    ModifyComparisonDetails,
    ModifyComparisonValuesDetails,
} from "../../stores/SurveyEditStore";
import { useStore } from "effector-react";

import { MultiComparison, MultiComparisonOperator, MultiQuestion } from "reliance-private-survey-schema/dist/API";

import { useCallback } from "react";

import * as _ from "lodash";

function MultiComparisonEdit(props: { comp: MultiComparison; index: number; condIndex: number }) {
    const handleChange = useCallback(
        (fieldName: string, fieldValue: string) => {
            var payload: ModifyComparisonDetails = {
                comp: _.cloneDeep(props.comp),
                index: props.index,
                condIndex: props.condIndex,
                fieldName: fieldName,
                fieldValue: fieldValue,
            };
            modifyComparisonDetails(payload);
        },
        [props]
    );
    const toggleOption = useCallback(
        (fieldValue: string) => {
            var payload: ModifyComparisonValuesDetails = {
                comp: _.cloneDeep(props.comp),
                index: props.index,
                condIndex: props.condIndex,
                type: "MultiComparison",
                value: fieldValue,
            };
            modifyComparisonValues(payload);
        },
        [props]
    );

    const survey_edit = useStore(SurveyEditStore);

    const dq = survey_edit.allQuestions[props.comp.questionId!] as unknown as MultiQuestion;

    return (
        <>
            {dq && (
                <div>
                    <div>
                        <b>Operator:</b>
                        <span className="px-3">
                            <select
                                id="multiComparisonOperator"
                                name="multiComparisonOperator"
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                value={props.comp.multiComparisonOperator}
                                onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                            >
                                <option value=""></option>
                                {Object.keys(MultiComparisonOperator).map((k) => (
                                    <option key={k} value={k}>
                                        {k}
                                    </option>
                                ))}
                            </select>
                        </span>
                    </div>
                    {["ONLY_SELECTION"].includes(props.comp.multiComparisonOperator ?? "") && (
                        <>
                            {/* multi values */}
                            {["MULTISELECT", "CHECKBOXES"].includes(dq.multiQuestionType ?? "") && (
                                <div>
                                    <b>Value:</b>
                                    <span className="px-3">
                                        <select
                                            id="value"
                                            name="value"
                                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                            value={props.comp.value ?? ""}
                                            onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                        >
                                            <option value=""></option>
                                            {(
                                                survey_edit.allQuestions[
                                                    props.comp.questionId!
                                                ] as unknown as MultiQuestion
                                            ).options?.map((k) => (
                                                <option key={k} value={k ?? ""}>
                                                    {k}
                                                </option>
                                            ))}
                                        </select>
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                    {/* MULTI VALUES */}
                    {[
                        "ANSWER_CONTAINS_ANY",
                        "ANSWER_CONTAINS_ALL",
                        "ANSWER_NOT_CONTAINS_ANY",
                        "ANSWER_NOT_CONTAINS_ALL",
                    ].includes(props.comp.multiComparisonOperator ?? "") && (
                        <div>
                            <div>
                                <b>Values:</b>
                            </div>
                            <div className="px-3">
                                {dq.options?.map((k) => (
                                    <span key={k}>
                                        <label className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                className="form-checkbox"
                                                checked={(props.comp!.values ?? ([] as String[])).includes(k!)}
                                                value={k!}
                                                onChange={(e) => toggleOption(e.target.value)}
                                            />
                                            <span className="ml-2">{k}</span>
                                        </label>
                                        <br />
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default MultiComparisonEdit;
