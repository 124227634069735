import SurveyEditStore, { setEditingDisqualification } from "../../stores/SurveyEditStore";
import { useStore } from "effector-react";

import { Disqualification } from "reliance-private-survey-schema/dist/API";

import * as _ from "lodash";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function DisqualificationBox(props: { dq: Disqualification; survey: string }) {
    const survey_edit = useStore(SurveyEditStore);
    return (
        <>
            {props.dq && (
                <>
                    <div
                        className={classNames(
                            "container border-4 border-dashed border-gray-200 rounded-lg mb-4 flex flex-col"
                        )}
                    >
                        <div
                            className={classNames(
                                _.isEqual(props.dq, survey_edit.allDisqualificationsOriginal[props.dq.id!])
                                    ? "bg-gray-200"
                                    : "bg-red-200"
                            )}
                            onClick={() => {
                                setEditingDisqualification(_.cloneDeep({ id: props.dq!.id!, context: props.survey }));
                            }}
                        >
                            <h2>{props.dq!.name}</h2>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
export default DisqualificationBox;
