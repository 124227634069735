import SurveyEditStore, { setEditingQuestion, handleOptInChange } from "../../stores/SurveyEditStore";
import { useStore } from "effector-react";

import { Question } from "reliance-private-survey-schema/dist/API";

import SingleQuestionBox from "../Questions/SingleQuestionBox";
import AddressQuestionBox from "../Questions/AddressQuestionBox";
import PersonQuestionBox from "../Questions/PersonQuestionBox";
import MultiQuestionBox from "../Questions/MultiQuestionBox";
import DateQuestionBox from "../Questions/DateQuestionBox";

import * as _ from "lodash";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function QuestionBox(props: { q: Question; survey: string }) {
    const survey_edit = useStore(SurveyEditStore);

    return (
        <>
            {props.q && (
                <>
                    <div
                        className={classNames(
                            "container border-4 border-dashed border-gray-200 rounded-lg mb-4 flex flex-col"
                        )}
                    >
                        <div
                            className={classNames(
                                _.isEqual(props.q, survey_edit.allQuestionsOriginal[props.q.id!])
                                    ? "bg-gray-200"
                                    : "bg-red-200"
                            )}
                            onClick={() => {
                                setEditingQuestion(
                                    _.cloneDeep({
                                        id: props.q!.id!,
                                        context: props.survey,
                                    })
                                );
                            }}
                        >
                            <h2>{props.q!.label}</h2>
                        </div>
                        <div
                            className={classNames(
                                props.q.optIn
                                    ? survey_edit.firmSurvey?.optInQuestions?.includes(props.q.id)
                                        ? "bg-green-100"
                                        : "bg-blue-100"
                                    : ""
                            )}
                        >
                            {props.q?.questionToken === "behalf_of" &&
                            survey_edit.sourceSurvey?.behalfOfLabel?.textLabel
                                ? survey_edit.sourceSurvey?.behalfOfLabel?.textLabel
                                : props.q!.text}
                            {props.q?.questionType === "SingleQuestion" && <SingleQuestionBox q={props.q} />}
                            {props.q?.questionType === "PersonQuestion" && <PersonQuestionBox q={props.q} />}
                            {props.q?.questionType === "AddressQuestion" && <AddressQuestionBox q={props.q} />}
                            {props.q?.questionType === "MultiQuestion" && <MultiQuestionBox q={props.q} />}
                            {props.q?.questionType === "DateQuestion" && <DateQuestionBox q={props.q} />}
                            {props.q.optIn && (
                                <div className="float-right">
                                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                        <input
                                            type="checkbox"
                                            checked={Boolean(
                                                survey_edit.firmSurvey?.optInQuestions?.includes(props.q.id)
                                            )}
                                            onChange={(e) =>
                                                handleOptInChange({ id: props.q.id, checked: e.target.checked })
                                            }
                                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                        />
                                        <label
                                            htmlFor="toggle"
                                            className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                                        ></label>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
export default QuestionBox;
