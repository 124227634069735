import SurveyEditStore, { modifyComparisonDetails, ModifyComparisonDetails } from "../../stores/SurveyEditStore";
import { useStore } from "effector-react";

import { DateQuestion, DateComparison, DateComparisonOperator, SolType } from "reliance-private-survey-schema/dist/API";

import { useCallback } from "react";

import * as _ from "lodash";

function DateComparisonEdit(props: { comp: DateComparison; index: number; condIndex: number }) {
    const survey_edit = useStore(SurveyEditStore);

    const dq = survey_edit.allQuestions[props.comp.questionId!] as unknown as DateQuestion;

    const handleChange = useCallback(
        (fieldName: string, fieldValue: string) => {
            var payload: ModifyComparisonDetails = {
                comp: _.cloneDeep(props.comp),
                index: props.index,
                condIndex: props.condIndex,
                fieldName: fieldName,
                fieldValue: fieldValue,
            };
            modifyComparisonDetails(payload);
        },
        [props]
    );

    return (
        <>
            {dq && (
                <div>
                    {["START_END_DATE"].includes(dq.dateQuestionType ?? "") && (
                        <div>
                            <b>Field:</b>
                            <span className="px-3">
                                <select
                                    id="questionIdField"
                                    name="questionIdField"
                                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                    value={props.comp.questionIdField ?? ""}
                                    onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                >
                                    <option value=""></option>
                                    {["Start Date", "End Date"].map((k, idx) => (
                                        <option key={k} value={idx}>
                                            {k}
                                        </option>
                                    ))}
                                </select>
                            </span>
                        </div>
                    )}
                    <div>
                        <b>Operator:</b>
                        <span className="px-3">
                            <select
                                id="dateComparisonOperator"
                                name="dateComparisonOperator"
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                value={props.comp.dateComparisonOperator}
                                onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                            >
                                <option value=""></option>
                                {Object.keys(DateComparisonOperator).map((k) => (
                                    <option key={k} value={k}>
                                        {k}
                                    </option>
                                ))}
                            </select>
                        </span>
                    </div>
                    {["DATE_COMPARE_LESS_THAN", "DATE_COMPARE_GREATER_THAN"].includes(
                        props.comp.dateComparisonOperator ?? ""
                    ) && (
                        <>
                            <div>
                                <b>Date (MM/DD/YYYY):</b>
                                <span className="px-3">
                                    <input
                                        type="date"
                                        id="date"
                                        name="date"
                                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        value={props.comp.date ?? ""}
                                        onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                    />
                                </span>
                            </div>
                        </>
                    )}
                    {["DATE_DELTA_ARBITRARY_LESS_THAN", "DATE_DELTA_ARBITRARY_GREATER_THAN"].includes(
                        props.comp.dateComparisonOperator ?? ""
                    ) && (
                        <>
                            <div>
                                <b>Date (MM/DD/YYYY):</b>
                                <span className="px-3">
                                    <input
                                        type="date"
                                        id="date"
                                        name="date"
                                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        value={props.comp.date ?? ""}
                                        onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                    />
                                </span>
                            </div>
                            <div>
                                <b>Delta (Days):</b>
                                <span className="px-3">
                                    <input
                                        type="number"
                                        id="delta"
                                        name="delta"
                                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        value={props.comp.delta ?? ""}
                                        onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                    />
                                </span>
                            </div>
                        </>
                    )}
                    {["AGE_AT_DATE_LESS_THAN", "AGE_AT_DATE_GREATER_THAN"].includes(
                        props.comp.dateComparisonOperator ?? ""
                    ) && (
                        <div>
                            <b>Age (Years):</b>
                            <span className="px-3">
                                <input
                                    type="number"
                                    id="delta"
                                    name="delta"
                                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                    value={props.comp.delta ?? ""}
                                    onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                />
                            </span>
                        </div>
                    )}
                    {["DATE_DELTA_TODAY_LESS_THAN", "DATE_DELTA_TODAY_GREATER_THAN"].includes(
                        props.comp.dateComparisonOperator ?? ""
                    ) && (
                        <div>
                            <b>Delta (Days):</b>
                            <span className="px-3">
                                <input
                                    type="number"
                                    id="delta"
                                    name="delta"
                                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                    value={props.comp.delta ?? ""}
                                    onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                />
                            </span>
                        </div>
                    )}
                    {[
                        "DATE_DELTA_OTHER_FIELD_LESS_THAN",
                        "DATE_DELTA_OTHER_FIELD_GREATER_THAN",
                        "SOL_DATE_STATE",
                    ].includes(props.comp.dateComparisonOperator ?? "") && (
                        <>
                            <div>
                                <b>Target Question ID:</b>
                                <span className="px-3">
                                    <select
                                        id="questionTarget"
                                        name="questionTarget"
                                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        value={props.comp.questionTarget ?? ""}
                                        onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                    >
                                        <option value=""></option>
                                        {Object.keys(survey_edit.allQuestions)
                                            .filter((qId, index) => {
                                                // dont allow to pick yourself (QUESTION)
                                                if (
                                                    survey_edit.selectedEditQuestion?.id &&
                                                    qId === survey_edit.selectedEditQuestion?.id
                                                ) {
                                                    return false;
                                                }
                                                // dont allow to pick a question not in correct context (QUESTION)
                                                if (
                                                    survey_edit.selectedEditQuestion?.id &&
                                                    survey_edit.allQuestions![qId]!.sourceId !==
                                                        survey_edit.selectedEditQuestionStorage[
                                                            survey_edit.selectedEditQuestion!.id!
                                                        ].sourceId
                                                ) {
                                                    return false;
                                                }
                                                // dont allow to pick a question not in correct context (DISQUALIFICATION)
                                                if (
                                                    (survey_edit.selectedEditDisqualification?.id &&
                                                        survey_edit.selectedEditDisqualification!.context.toLowerCase() ===
                                                            "global" &&
                                                        survey_edit.allQuestions[qId].sourceId!.toLowerCase() !==
                                                            "global") ||
                                                    (survey_edit.selectedEditDisqualification?.id &&
                                                        survey_edit.selectedEditDisqualification!.context.toLowerCase() !==
                                                            "global" &&
                                                        survey_edit.allQuestions[qId].sourceId!.toLowerCase() ===
                                                            "global")
                                                ) {
                                                    return false;
                                                }
                                                // dont allow to pick a question that is not currently linked
                                                if (
                                                    !survey_edit.globalSurveyQuestionIds.includes(qId) &&
                                                    !survey_edit.sourceSurveyQuestionIds.includes(qId) &&
                                                    !survey_edit.firmSurveyQuestionIds.includes(qId)
                                                ) {
                                                    return false;
                                                }
                                                if (props.comp.dateComparisonOperator !== "SOL_DATE_STATE") {
                                                    // only date comparisons
                                                    return ["DateQuestion"].includes(
                                                        survey_edit.allQuestions![qId]!.questionType!
                                                    )
                                                        ? true
                                                        : false;
                                                } else {
                                                    // only address comparisons
                                                    return ["AddressQuestion"].includes(
                                                        survey_edit.allQuestions![qId]!.questionType!
                                                    )
                                                        ? true
                                                        : false;
                                                }
                                            })
                                            .map((qId, index) => (
                                                <option key={index} value={qId}>
                                                    {qId}
                                                </option>
                                            ))}
                                    </select>
                                </span>
                            </div>
                            {["DATE_DELTA_OTHER_FIELD_LESS_THAN", "DATE_DELTA_OTHER_FIELD_GREATER_THAN"].includes(
                                props.comp.dateComparisonOperator ?? ""
                            ) && (
                                <div>
                                    <b>Delta (Days):</b>
                                    <span className="px-3">
                                        <input
                                            type="number"
                                            id="delta"
                                            name="delta"
                                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                            value={props.comp.delta ?? ""}
                                            onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                        />
                                    </span>
                                </div>
                            )}
                            {["SOL_DATE_STATE"].includes(props.comp.dateComparisonOperator ?? "") && (
                                <>
                                    <div>
                                        <b>SOL Type:</b>
                                        <span className="px-3">
                                            <select
                                                id="solType"
                                                name="solType"
                                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                                value={props.comp.solType ?? ""}
                                                onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                            >
                                                <option value=""></option>
                                                {Object.keys(SolType).map((k) => (
                                                    <option key={k} value={k}>
                                                        {k}
                                                    </option>
                                                ))}
                                            </select>
                                        </span>
                                    </div>
                                    <div>
                                        <b>Buffer Days:</b>
                                        <span className="px-3">
                                            <input
                                                type="number"
                                                id="bufferDays"
                                                name="bufferDays"
                                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                                value={props.comp.bufferDays ?? 0}
                                                onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                            />
                                        </span>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {props.comp.questionTarget &&
                        ["START_END_DATE"].includes(
                            (survey_edit.allQuestions[props.comp.questionTarget] as unknown as DateQuestion)
                                .dateQuestionType ?? ""
                        ) && (
                            <div>
                                <b>Field:</b>
                                <span className="px-3">
                                    <select
                                        id="questionTargetField"
                                        name="questionTargetField"
                                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        value={props.comp.questionTargetField ?? ""}
                                        onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                                    >
                                        <option value=""></option>
                                        {["Start Date", "End Date"].map((k, idx) => (
                                            <option key={k} value={idx}>
                                                {k}
                                            </option>
                                        ))}
                                    </select>
                                </span>
                            </div>
                        )}
                </div>
            )}
        </>
    );
}

export default DateComparisonEdit;
