import { useState, useEffect, useCallback } from "react";
import { API } from "aws-amplify";

import { MainRecord, Source } from "reliance-private-survey-schema/dist/API";

import { useForm, SubmitHandler } from "react-hook-form";

import * as _ from "lodash";

type Inputs = {
    sourceId: string;
};

type ExistingStorage = Record<string, MainRecord[]>;

function ContextSelectorMatch() {
    const [sources, sourcesSet] = useState([] as Source[]);
    const [selectedSource, selectedSourceSet] = useState("");
    const [existingSurveysBySource, existingSurveysBySourceSet] = useState<ExistingStorage>({});
    const { register, handleSubmit } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        window.location.href = `/match/${data.sourceId}`;
    };

    const updateExistingSurveys = useCallback(
        async (sourceId: string) => {
            selectedSourceSet(sourceId);
            const getMainRecordsBySource = /* GraphQL */ `
                query getMainRecordsBySource($sourceId: ID!) {
                    getMainRecordsBySource(sourceId: $sourceId) {
                        id
                        sourceId
                        firmId
                    }
                }
            `;

            if (!existingSurveysBySource[sourceId]) {
                const responseGetMainRecordsBySource: any = await API.graphql({
                    query: getMainRecordsBySource,
                    variables: { sourceId: sourceId },
                });
                if (responseGetMainRecordsBySource.data.getMainRecordsBySource !== null) {
                    var newIds: MainRecord[] = [];
                    for (let q of responseGetMainRecordsBySource.data.getMainRecordsBySource) {
                        newIds.push(q);
                    }
                    var newStorage = _.cloneDeep(existingSurveysBySource);
                    newStorage[sourceId] = newIds;
                    existingSurveysBySourceSet(newStorage);
                }
            }
        },
        [existingSurveysBySource]
    );

    useEffect(() => {
        const getSources = /* GraphQL */ `
            query GetSources {
                getSources {
                    id
                    name
                }
            }
        `;
        const loadData = async () => {
            const response: any = await API.graphql({ query: getSources });
            sourcesSet(
                response.data.getSources.sort((a, b) => {
                    const A = a.name!.toUpperCase();
                    const B = b.name!.toUpperCase();
                    let comparison = 0;
                    if (A > B) {
                        comparison = 1;
                    } else if (A < B) {
                        comparison = -1;
                    }
                    return comparison;
                })
            );
        };
        loadData();
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="inline-block pr-2 align-top">
                    <label htmlFor="sourceId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Source
                    </label>
                    <div>
                        <select
                            id="sourceId"
                            name="sourceId"
                            ref={register({ required: true })}
                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            onChange={(e) => updateExistingSurveys(e.target.value)}
                        >
                            <option value=""></option>
                            {sources.map((source: any, index: number) => (
                                <option key={index} value={source.id}>
                                    {source.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <button
                    type="submit"
                    className="mt-7 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                >
                    Go!
                </button>
            </form>
            Number of Existing Surveys:{" "}
            {selectedSource && (
                <span>
                    {
                        existingSurveysBySource[selectedSource]?.filter((s, idx) => {
                            return s.firmId !== "GLOBAL";
                        }).length
                    }
                </span>
            )}
        </>
    );
}
export default ContextSelectorMatch;
