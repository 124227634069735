import { Question, MultiQuestion } from "reliance-private-survey-schema/dist/API";

import { States } from "reliance-private-survey-parser/dist/surveyParseTypes";

function MultiQuestionBox(props: { q: Question }) {
    const q = props.q as unknown as MultiQuestion;

    return (
        <>
            <div>
                {q!.multiQuestionType === "CHECKBOXES" && (
                    <div>
                        {q!.options?.map((v) => {
                            return (
                                <label key={v!} className="inline-flex items-center">
                                    <input type="checkbox" className="form-radio" name="value" value={v!} />
                                    <span className="ml-2">{v}</span>
                                </label>
                            );
                        })}
                    </div>
                )}
                {q!.multiQuestionType === "STATES" && (
                    <div>
                        {Object.keys(States).map((v) => {
                            return (
                                <label key={v!} className="inline-flex items-center">
                                    <input type="checkbox" className="form-radio" name="value" value={v!} />
                                    <span className="ml-2">{States[v]}</span>
                                </label>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
}
export default MultiQuestionBox;
