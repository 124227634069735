import SurveyEditStore, { addQuestion, NewQuestionInputs } from "../../stores/SurveyEditStore";
import { useStore } from "effector-react";

import { useForm, SubmitHandler } from "react-hook-form";

import { useCallback } from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function NewQuestionBox(props: { closeCallback: (a: boolean) => void; survey: string }) {
    const survey_edit = useStore(SurveyEditStore);

    const { reset, register, handleSubmit } = useForm<NewQuestionInputs>();

    const handleClose = useCallback(() => {
        reset();
        props.closeCallback(false);
    }, [reset, props]);

    const onSubmit: SubmitHandler<NewQuestionInputs> = (data) => {
        // see if questionToken already exists

        var exists = false;

        console.log(Object.keys(survey_edit.allQuestions));

        for (let qId of Object.keys(survey_edit.allQuestions)) {
            console.log(`checking ${survey_edit.allQuestions[qId]?.questionToken} === ${data.questionToken}`);
            if (survey_edit.allQuestions[qId]?.questionToken === data.questionToken) {
                exists = true;
                break;
            }
        }

        if (exists) {
            alert("ID already exists! Choose another");
            return false;
        }

        doCallbackSubmit(data);
    };

    const doCallbackSubmit = useCallback(
        (data: NewQuestionInputs) => {
            addQuestion(data);
            reset();
            props.closeCallback(false);
        },
        [reset, props]
    );

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div
                    className={classNames(
                        "container border-4 border-dashed border-gray-200 rounded-lg mb-4 flex flex-col"
                    )}
                >
                    <div>
                        <b>Token:</b>
                        <span className="px-3">
                            <input
                                id="questionToken"
                                name="questionToken"
                                type="text"
                                ref={register({ required: true })}
                            />
                        </span>
                    </div>
                    <div>
                        <b>questionType:</b>
                        <span className="px-3">
                            <select
                                id="questionType"
                                name="questionType"
                                ref={register({ required: true })}
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            >
                                <option value=""></option>
                                <option value="SingleQuestion">SingleQuestion</option>
                                <option value="MultiQuestion">MultiQuestion</option>
                                <option value="DateQuestion">DateQuestion</option>
                                <option value="PersonQuestion">PersonQuestion</option>
                                <option value="AddressQuestion">AddressQuestion</option>
                            </select>
                        </span>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="m-6 p-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            SAVE
                        </button>
                        <button
                            type="button"
                            className="m-6 p-6 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => handleClose()}
                        >
                            CANCEL
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}
export default NewQuestionBox;
