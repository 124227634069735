import SurveyEditStore, { setEditContext } from "../stores/SurveyEditStore";
import { useStore } from "effector-react";

const tabs = [{ name: "Edit" }, { name: "Existing" }];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Example() {
    const survey_edit = useStore(SurveyEditStore);
    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    defaultValue={tabs[0].name}
                >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex" aria-label="Tabs">
                        {tabs?.map((tab) => (
                            <button
                                key={tab.name}
                                className={classNames(
                                    survey_edit.UiSideSection?.toLowerCase() === tab.name.toLowerCase()
                                        ? "border-indigo-500 text-indigo-600"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm flex-grow"
                                )}
                                onClick={() =>
                                    setEditContext({ UiSideSection: tab.name.replace(" ", "").toLowerCase() })
                                }
                            >
                                {tab.name}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    );
}
