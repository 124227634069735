import SurveyEditStore, {
    addCondition,
    modifyStorageDisqualificationDetails,
    resetEditingDisqualification,
    saveEditingDisqualification,
    removeDisqualification,
    ModifyDisqualificationDetails,
} from "../../stores/SurveyEditStore";
import { useStore } from "effector-react";

import ConditionBox from "../Comparisons/ConditionBox";

import { useCallback } from "react";

function DisqualificationEdit() {
    const survey_edit = useStore(SurveyEditStore);
    const dqData = survey_edit.selectedEditDisqualification;
    const dq = survey_edit.selectedEditDisqualificationStorage[dqData!.id];

    const handleChange = useCallback(
        (fieldName: string, fieldValue: string) => {
            var payload: ModifyDisqualificationDetails = {
                id: dq.id!,
                fieldName: fieldName,
                fieldValue: fieldValue,
            };
            if (fieldName.includes(".")) {
                const x = fieldName.split(".");
                const newList = (dq[x[0]] ?? []) as string[];
                newList[x[1]] = fieldValue;
                payload.fieldName = x[0];
                payload.fieldValue = newList;
                modifyStorageDisqualificationDetails(payload);
            } else {
                modifyStorageDisqualificationDetails(payload);
            }
        },
        [dq]
    );

    return (
        <div className="p-2 mb-40">
            {dq && (
                <div className="flex flex-col">
                    <div>
                        <b>Type:</b>
                        <span className="px-3">Disqualification</span>
                    </div>
                    <div>
                        <b>Disqualfication ID:</b>
                        <span className="px-3">{dqData?.id}</span>
                    </div>
                    <div>
                        <b>Survey:</b>
                        <span className="px-3">{dqData?.context}</span>
                    </div>
                    <div>
                        <b>Source ID:</b>
                        <span className="px-3">{dq.sourceId}</span>
                    </div>
                    <div>
                        <b>Name:</b>
                        <span className="px-3">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                value={dq?.name ?? ""}
                                onChange={(e) => handleChange(e.target.name!, e.target.value!)}
                            />
                        </span>
                    </div>
                    <div>
                        <b>DQ Conditions:</b>{" "}
                        <button type="button" onClick={() => addCondition()}>
                            ADD+
                        </button>
                        <span className="px-3">
                            {dq.conditions?.map((cond, index) => (
                                <ConditionBox cond={cond!} key={index} index={index} />
                            ))}
                        </span>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="m-6 p-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => saveEditingDisqualification()}
                        >
                            SUBMIT
                        </button>
                        <button
                            type="button"
                            className="m-6 p-6 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => resetEditingDisqualification()}
                        >
                            RESET
                        </button>
                        <button
                            type="button"
                            className="m-6 p-6 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => removeDisqualification()}
                        >
                            REMOVE
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
export default DisqualificationEdit;
