import { Question, SingleQuestion } from "reliance-private-survey-schema/dist/API";
import SurveyEditStore from "../../stores/SurveyEditStore";
import { useStore } from "effector-react";
function SingleQuestionBox(props: { q: Question }) {
    const survey_edit = useStore(SurveyEditStore);
    const q = props.q as unknown as SingleQuestion;

    return (
        <>
            <div>
                {q!.singleQuestionType === "TEXT" && (
                    <div>
                        <input type="text" className="form-text" name="value" />
                    </div>
                )}
                {q!.singleQuestionType === "TEXTAREA" && (
                    <div>
                        <textarea className="form-textarea mt-1 block w-full" rows={5} name="value" />
                    </div>
                )}
                {q!.singleQuestionType === "SELECT" && (
                    <div>
                        <select
                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            name="value"
                        >
                            <option value=""></option>
                            {q!.options?.map((v) => {
                                return (
                                    <option key={v} value={v!}>
                                        {v}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                )}
                {q!.singleQuestionType === "BOOLEAN" && (
                    <div>
                        <label className="inline-flex items-center">
                            <input type="radio" className="form-radio" name="value" value="Yes" />
                            <span className="ml-2">Yes</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input type="radio" className="form-radio" name="value" value="No" />
                            <span className="ml-2">No</span>
                        </label>
                    </div>
                )}
                {q!.singleQuestionType === "RADIO" && (
                    <div>
                        {q!.options?.map((v) => {
                            return (
                                <label key={v!} className="inline-flex items-center">
                                    <input type="radio" className="form-radio" name="value" value={v!} />
                                    <span className="ml-2">
                                        {q!.questionToken === "behalf_of" &&
                                        survey_edit.sourceSurvey?.behalfOfLabel?.[
                                            v === "Self" ? "selfLabel" : "otherLabel"
                                        ]
                                            ? survey_edit.sourceSurvey?.behalfOfLabel?.[
                                                  v === "Self" ? "selfLabel" : "otherLabel"
                                              ]
                                            : v}
                                    </span>
                                </label>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
}
export default SingleQuestionBox;
