import { API } from "aws-amplify";

import { Survey } from "reliance-private-survey-schema/dist/API";

import FillOutSurvey from "../components/Survey/FillOutSurvey";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

type routeParams = {
    sourceId: string;
};
function Match() {
    const route_params: routeParams = useParams();
    const [surveyId, surveyIdSet] = useState("");

    useEffect(() => {
        const addSurveyUnmatched = /* GraphQL */ `
            mutation AddSurveyUnmatched($sourceId: ID!, $leadId: ID) {
                addSurveyUnmatched(sourceId: $sourceId, leadId: $leadId) {
                    id
                }
            }
        `;
        async function loadData(sourceId) {
            var promises: any = {};

            /// blank survey
            promises["survey"] = API.graphql({
                query: addSurveyUnmatched,
                variables: { sourceId: route_params.sourceId },
            });

            var values = await Promise.all([promises["survey"]]);

            const responseTypedSurvey: Partial<Survey> = values[0].data.addSurveyUnmatched;
            surveyIdSet(responseTypedSurvey.id!);
        }
        loadData(route_params.sourceId);
    }, [route_params.sourceId]);

    return (
        <main className="flex-1 relative h-screen overflow-y-auto focus:outline-none">
            <div className="">
                {!surveyId && <p>CREATING SURVEY FROM CONFIGURATIONS...</p>}
                {surveyId && <FillOutSurvey surveyId={surveyId} />}
            </div>
        </main>
    );
}
export default Match;
