import { Question, DateQuestion } from "reliance-private-survey-schema/dist/API";

function DateQuestionBox(props: { q: Question; handleChangeCallback: (q: string, n: string, v: string) => void }) {
    const q = props.q as unknown as DateQuestion;

    return (
        <>
            <div>
                {q!.dateQuestionType === "DATE" && (
                    <div>
                        <input
                            placeholder="mm/dd/YYYY"
                            type="date"
                            className="form-text"
                            name={`date#id#${q.questionToken!}`}
                            onChange={(e) =>
                                props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                            }
                        />
                    </div>
                )}
                {q!.dateQuestionType === "DATETIME" && (
                    <div>
                        <input
                            placeholder="mm/dd/YYYY hh:mm a"
                            type="datetime-local"
                            className="form-text"
                            name={`datetime#id#${q.questionToken!}`}
                            onChange={(e) =>
                                props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                            }
                        />
                    </div>
                )}
                {q!.dateQuestionType === "START_END_DATE" && (
                    <div>
                        <input
                            placeholder="START mm/dd/YYYY"
                            type="date"
                            className="form-text"
                            name={`dates.0#id#${q.questionToken!}`}
                            onChange={(e) =>
                                props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                            }
                        />
                        <input
                            placeholder="END mm/dd/YYYY"
                            type="date"
                            className="form-text"
                            name={`dates.1#id#${q.questionToken!}`}
                            onChange={(e) =>
                                props.handleChangeCallback(q.questionToken, e.target.name!, e.target.value!)
                            }
                        />
                    </div>
                )}
            </div>
        </>
    );
}
export default DateQuestionBox;
