import {
    addDisqualification,
    setEditingDisqualification,
    NewDisqualificationInputs,
} from "../../stores/SurveyEditStore";

import { v4 as uuidv4 } from "uuid";

import { useForm, SubmitHandler } from "react-hook-form";

// import * as _ from "lodash";

import { useCallback } from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function NewDisqualificationBox(props: { closeCallback: (a: boolean) => void; survey: string }) {
    const { reset, register, handleSubmit } = useForm<NewDisqualificationInputs>();

    const handleClose = useCallback(() => {
        reset();
        props.closeCallback(false);
    }, [reset, props]);

    const onSubmit: SubmitHandler<NewDisqualificationInputs> = (data) => {
        doCallbackSubmit({ ...data, id: uuidv4() });
    };

    const doCallbackSubmit = useCallback(
        (data: NewDisqualificationInputs) => {
            addDisqualification(data);
            reset();
            props.closeCallback(false);
            setEditingDisqualification({
                id: data.id,
                context: props.survey,
            });
        },
        [reset, props]
    );

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div
                    className={classNames(
                        "container border-4 border-dashed border-gray-200 rounded-lg mb-4 flex flex-col"
                    )}
                >
                    <div>
                        <b>Name:</b>
                        <span className="px-3">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                ref={register({ required: true })}
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            />
                        </span>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="m-6 p-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            SAVE
                        </button>
                        <button
                            type="button"
                            className="m-6 p-6 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => handleClose()}
                        >
                            CANCEL
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}
export default NewDisqualificationBox;
