import { useParams } from "react-router-dom";

type routeParams = {
    system: string;
    leadId: string;
};
function Lead() {
    const route_params: routeParams = useParams();
    return (
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
            <div className="bg-blue-500">
                <span className="px-2">
                    You are connected to
                    <b>
                        {route_params.system} #{route_params.leadId}
                    </b>
                </span>
                <button
                    className={`bg-white hover:bg-gray-100 text-s text-gray-800 font-semibold py-0 px-0 border border-gray-400 rounded shadow`}
                >
                    PROMOTE
                </button>
            </div>
            <div className=""></div>
        </main>
    );
}
export default Lead;
