import { useStore } from "effector-react";

import { Link } from "react-router-dom";

import AuthUserStore from "reliance-private-effector-stores/dist/AuthUserStore";

import { Auth } from "aws-amplify";

import google_icon from "../assets/google-logo.png";

function Dashboard() {
    const auth_user_store = useStore(AuthUserStore);
    return (
        <>
            <main className="flex-1 relative overflow-y-auto focus:outline-none">
                <div className="py-6">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                        <div className="py-4">
                            {/* Hero card */}
                            <div className="relative">
                                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
                                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                                    <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                                        <div className="absolute inset-0">
                                            <img
                                                className="h-full w-full object-cover"
                                                src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                                                alt="People working on laptops"
                                            />
                                            <div className="absolute inset-0 bg-indigo-700 mix-blend-multiply" />
                                        </div>
                                        <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                                            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                                                <span className="block text-white">Take control of your</span>
                                                <span className="block text-indigo-200">survey building</span>
                                            </h1>
                                            <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                                                No one wants to spend all day editing PHP code and waiting 20 minutes to
                                                add a single question. Let's speed this shit up.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {!auth_user_store.isLoggedIn && (
                <div className="mt-5 w-24 mx-auto justify-center">
                    <div>
                        <div>
                            <p className="text-sm font-medium text-gray-700">Sign in with</p>

                            <div className="mt-1 ">
                                <div>
                                    <button
                                        onClick={() =>
                                            Auth.federatedSignIn({
                                                customProvider: "Google",
                                            })
                                        }
                                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    >
                                        <img src={google_icon} alt="Google" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {auth_user_store.isLoggedIn && (
                <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                    <Link
                        to="/admin"
                        className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
                    >
                        Get started
                    </Link>
                </div>
            )}
        </>
    );
}

export default Dashboard;
