import ContextSelectorEdit from "../components/ContextSelectorEdit";
import ContextSelectorMatch from "../components/ContextSelectorMatch";

import SurveyEditStore, {
    PartialSurveyEdit,
    setEditContext,
    modifyStorageQuestionDetails,
    ModifyQuestionDetails,
} from "../stores/SurveyEditStore";
import { useStore } from "effector-react";

import Tabs from "../components/Tabs";
import MainTabs from "../components/MainTabs";

import QuestionList from "../components/QuestionList";
import DisqualificationList from "../components/DisqualificationList";

import QuestionEdit from "../components/Questions/QuestionEdit";
import QuestionExisting from "../components/QuestionExisting";

import DisqualificationExisting from "../components/DisqualificationExisting";

import DisqualificationEdit from "../components/Disqualifications/DisqualificationEdit";

import { DragDropContext } from "react-beautiful-dnd";

import {
    LinkedQuestion,
    MultiQuestion,
    SingleQuestion,
    Disqualification,
} from "reliance-private-survey-schema/dist/API";

import { Redirect } from "react-router-dom";
import AuthUserStore from "reliance-private-effector-stores/dist/AuthUserStore";

import * as _ from "lodash";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function Admin() {
    const survey_edit = useStore(SurveyEditStore);
    const auth_user_store = useStore(AuthUserStore);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        if (result.source.droppableId === "questionList" && result.destination.droppableId === "questionList") {
            const context = `${survey_edit.UiMainSection}Survey`;
            const items = reorder(survey_edit[context].linkedQuestions, result.source.index, result.destination.index);
            const se: PartialSurveyEdit = {};
            se[context] = {
                ...survey_edit[context]!,
                linkedQuestions: items as LinkedQuestion[],
            };
            setEditContext(se);
        }

        if (
            result.source.droppableId === "disqualificationList" &&
            result.destination.droppableId === "disqualificationList"
        ) {
            const context = `${survey_edit.UiMainSection.replace("dq", "")}Survey`;
            const items = reorder(
                survey_edit[context].disqualifications,
                result.source.index,
                result.destination.index
            );
            const se: PartialSurveyEdit = {};
            se[context] = {
                ...survey_edit[context]!,
                disqualifications: items as Disqualification[],
            };
            setEditContext(se);
        }

        if (result.source.droppableId === "options" && result.destination.droppableId === "options") {
            var specQ: any = survey_edit.selectedEditQuestionStorage[survey_edit.selectedEditQuestion!.id];

            var q: SingleQuestion | MultiQuestion | undefined = undefined;

            if (specQ.questionType === "SingleQuestion") {
                q = _.cloneDeep(specQ) as SingleQuestion;
            } else if (specQ.questionType === "MultiQuestion") {
                q = _.cloneDeep(specQ) as MultiQuestion;
            }

            const items = reorder(q!.options, result.source.index, result.destination.index) as string[];
            var payload: ModifyQuestionDetails = {
                id: q!.id,
                fieldName: "options",
                fieldValue: items,
            };
            modifyStorageQuestionDetails(payload);
        }
    };

    return (
        <>
            {!auth_user_store.isAdmin && <Redirect to="/agent" />}
            {auth_user_store.isAdmin && !survey_edit.isEditing && (
                <main className="flex-1 relative focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-semibold text-gray-900">Surveys</h1>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="py-4">
                                <div className="border-4 border-dashed border-gray-200 rounded-lg">
                                    <div className="p-8">
                                        <h1 className="text-2xl font-bold">Edit Survey</h1>
                                        <p>
                                            <strong>You're about to start editing a survey!</strong> Your changes won't
                                            be saved until you preview it and confirm that you want to commit the
                                            changes.
                                        </p>
                                        <ContextSelectorEdit />
                                    </div>
                                    <div className="p-8">
                                        <h1 className="text-2xl font-bold">Test Survey Matching</h1>
                                        <p>
                                            This will allow you to match/test a survey from the top, as the agents do.
                                        </p>
                                        <ContextSelectorMatch />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
            {auth_user_store.isAdmin && survey_edit.isEditing && (
                <main className="h-full">
                    <div className="w-full fixed top-0 bg-color-white">
                        <div className="h-full flex">
                            <div className="flex-none w-3/5">
                                <div className="h-12">
                                    <MainTabs />
                                </div>
                            </div>
                            <div className="flex-none right-0 w-2/5">
                                <div className="h-12">
                                    <Tabs />
                                </div>
                            </div>
                        </div>
                    </div>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className="h-full flex pt-14 h-screen">
                            <div className="flex-none w-3/5 overflow-scroll h-screen">
                                {["global", "source", "firm"].includes(survey_edit.UiMainSection) && (
                                    <QuestionList survey={survey_edit.UiMainSection} />
                                )}
                                {["globaldq", "firmdq"].includes(survey_edit.UiMainSection) && (
                                    <DisqualificationList survey={survey_edit.UiMainSection.replace("dq", "")} />
                                )}
                            </div>
                            <div className="flex-none w-2/5 overflow-scroll h-screen">
                                {survey_edit.UiSideSection === "edit" && survey_edit.selectedEditQuestion && (
                                    <QuestionEdit />
                                )}
                                {survey_edit.UiSideSection === "edit" && survey_edit.selectedEditDisqualification && (
                                    <DisqualificationEdit />
                                )}
                                {survey_edit.UiSideSection === "edit" &&
                                    !survey_edit.selectedEditQuestion &&
                                    !survey_edit.selectedEditDisqualification && (
                                        <div className="p-2">
                                            <p className="mx-3">Please Select an Item to edit</p>
                                        </div>
                                    )}
                                {survey_edit.UiSideSection === "existing" && survey_edit.UiMainSection !== "firmdq" && (
                                    <QuestionExisting />
                                )}
                                {survey_edit.UiSideSection === "existing" && survey_edit.UiMainSection === "firmdq" && (
                                    <DisqualificationExisting />
                                )}
                            </div>
                        </div>
                    </DragDropContext>
                </main>
            )}
        </>
    );
}
export default Admin;
