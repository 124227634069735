function Error() {
    return (
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
            <div className="py-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">Error!</h1>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1>You shouldn't be here!</h1>
                    <div className="py-4">
                        <div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />
                    </div>
                    {/* /End replace */}
                </div>
            </div>
        </main>
    );
}
export default Error;
