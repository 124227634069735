import { API } from "aws-amplify";

import SurveyFillStore, { setFillContext, modifyStorageAnswerDetails } from "../../stores/SurveyFillStore";

import { ModifyStorageAnswerDetails } from "reliance-private-survey-parser/dist/surveyParseTypes";

import { useStore } from "effector-react";

import {
    Question,
    Survey,
    ContextConfiguration,
    MainRecord,
    SurveyStatus,
} from "reliance-private-survey-schema/dist/API";

import { fragmentContextConfiguration } from "reliance-private-survey-schema/dist/customGraphql/common";
import { getSurvey } from "reliance-private-survey-schema/dist/graphql/queries";

import FillOutQuestionBox from "./FillOutQuestionBox";

import { useEffect, useCallback } from "react";

function FillOutSurvey(props: { surveyId: string }) {
    const survey_fill = useStore(SurveyFillStore);

    const handleChange = useCallback((questionToken: string, fieldName: string, fieldValue: string | boolean) => {
        var payload: ModifyStorageAnswerDetails = {
            questionToken: questionToken,
            fieldName: fieldName,
            fieldValue: fieldValue,
        };
        modifyStorageAnswerDetails(payload);
    }, []);

    useEffect(() => {
        const getMainRecordsBySource = /* GraphQL */ `
            query GetMainRecordsBySource($sourceId: ID!) {
                getMainRecordsBySource(sourceId: $sourceId) {
                    id
                    firmId
                    sourceId
                    contextConfiguration {
                        ...ContextConfigurationFragment
                    }
                }
            }
        `;
        const getMainRecord = /* GraphQL */ `
            query GetMainRecord($firmId: ID!, $sourceId: ID!) {
                getMainRecord(firmId: $firmId, sourceId: $sourceId) {
                    id
                    firmId
                    sourceId
                    contextConfiguration {
                        ...ContextConfigurationFragment
                    }
                }
            }
        `;

        async function doLoadSurvey(surveyId) {
            /// load survey
            const response: any = await API.graphql({
                query: getSurvey,
                variables: { id: props.surveyId },
            });
            const responseTypedSurvey: Survey = response.data.getSurvey;

            /// load matching items if needed
            var promises: any = {};
            var globalContextConfiguration: Partial<ContextConfiguration> = {};
            var sourceContextConfiguration: Partial<ContextConfiguration> = {};
            var firmContextConfigurations: Record<string, Partial<ContextConfiguration>> = {};

            var desiredFirmOrder: string[] = [];

            if (responseTypedSurvey.status === SurveyStatus.INIT) {
                /// all sets for this source, including global
                promises["source"] = API.graphql({
                    query: getMainRecordsBySource + fragmentContextConfiguration,
                    variables: { sourceId: responseTypedSurvey.sourceId },
                });

                /// all sets for this source, including global
                promises["global"] = API.graphql({
                    query: getMainRecord + fragmentContextConfiguration,
                    variables: { firmId: "GLOBAL", sourceId: "GLOBAL" },
                });

                var values = await Promise.all([promises["global"], promises["source"]]);

                /// set global
                globalContextConfiguration = values[0].data.getMainRecord.contextConfiguration as ContextConfiguration;

                /// set source
                sourceContextConfiguration = (values[1].data.getMainRecordsBySource as MainRecord[]).filter((cr) => {
                    return cr.firmId === "GLOBAL";
                })[0].contextConfiguration!;

                /// set firms
                (values[1].data.getMainRecordsBySource as MainRecord[])
                    .filter((cr) => {
                        return cr.firmId !== "GLOBAL";
                    })
                    .forEach((cr, idx) => {
                        firmContextConfigurations[cr.firmId] = (values[1].data.getMainRecordsBySource as MainRecord[])[
                            idx
                        ].contextConfiguration!;
                    });

                /// set firmorder (for now just included all firms in order)
                (values[1].data.getMainRecordsBySource as MainRecord[])
                    .filter((cr) => {
                        return cr.firmId !== "GLOBAL";
                    })
                    .forEach((cr, idx) => {
                        desiredFirmOrder.push(cr.firmId);
                    });
            }

            setFillContext({
                survey: responseTypedSurvey,
                viewingMode: "PREVIEW",
                globalContextConfiguration: globalContextConfiguration,
                sourceContextConfiguration: sourceContextConfiguration,
                firmContextConfigurations: firmContextConfigurations,
                desiredFirmOrder: desiredFirmOrder,
            });
        }
        doLoadSurvey(props.surveyId);
    }, [props.surveyId]);

    return (
        <>
            <div className="flex">
                <div className="flex-none w-2/3 mb-40">
                    {!survey_fill.survey && <p>LOADING SURVEY...</p>}
                    {survey_fill.parsed &&
                        survey_fill.survey?.status === SurveyStatus.INIT &&
                        survey_fill.matchQuestions?.map((q, idx) => (
                            <FillOutQuestionBox
                                key={`init#${q?.questionToken}#idx`}
                                q={q as Question}
                                handleChangeCallback={handleChange}
                            />
                        ))}
                    {survey_fill.parsed &&
                        survey_fill.survey?.status === SurveyStatus.MATCHED &&
                        survey_fill.survey.questions?.map((q, idx) => (
                            <FillOutQuestionBox
                                key={`matched#${q?.questionToken}#idx`}
                                q={q as Question}
                                handleChangeCallback={handleChange}
                            />
                        ))}
                </div>
                <div className="border-3 flex-none fixed right-0 w-1/3 p-4">
                    {survey_fill.surveyParseStatus.dq && (
                        <div>
                            <div className="m-3 border-solid border-2 border-black w-11/12 mx-auto bg-red-500 text-4xl text-center text-white">
                                DQ!
                            </div>
                        </div>
                    )}
                    {survey_fill.survey?.status === SurveyStatus.INIT &&
                        survey_fill.surveyMatchStatus.matchedFirmId && (
                            <div>
                                <div className="m-3 border-solid border-2 border-black w-11/12 mx-auto bg-green-500 text-4xl text-center text-white">
                                    MATCH #{survey_fill.surveyMatchStatus.matchedFirmId}!
                                </div>
                            </div>
                        )}
                    <p>
                        <b>Status:</b> {survey_fill.survey?.status}
                    </p>
                    {survey_fill.survey?.status === SurveyStatus.INIT && (
                        <p>
                            <b>Firms:</b> {JSON.stringify(survey_fill.desiredFirmOrder)}
                        </p>
                    )}
                    {survey_fill.survey?.status === SurveyStatus.MATCHED && (
                        <p>
                            <b>Firm:</b> {survey_fill.survey.firm?.name}
                        </p>
                    )}
                    <p>
                        <b>Parse Status</b>
                    </p>
                    <p>All Answered?: {survey_fill.surveyParseStatus.answered ? "YES" : "NO"}</p>
                    <p>DQ Reasons: </p>
                    {Object.keys(survey_fill.surveyParseStatus.dqExplain).map((dqKey, dqIdx) => {
                        return survey_fill.surveyParseStatus.dqExplain[dqKey].length > 0 ? (
                            <div key={dqIdx}>
                                <b>{dqKey}</b>
                                {survey_fill.surveyParseStatus.dqExplain[dqKey].map((r, rIdx) => (
                                    <li key={rIdx}>{r}</li>
                                ))}
                            </div>
                        ) : (
                            <div key={`empty#${dqIdx}`}></div>
                        );
                    })}
                    {survey_fill.survey?.status === SurveyStatus.INIT && (
                        <>
                            <p>
                                <b>Match Status</b>
                            </p>
                            {Object.keys(survey_fill.surveyMatchStatus).map((k, idx) => (
                                <p key={idx}>
                                    {k}: {JSON.stringify(survey_fill.surveyMatchStatus[k])}
                                </p>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
export default FillOutSurvey;
