import { Question, AddressQuestion } from "reliance-private-survey-schema/dist/API";

import { States } from "reliance-private-survey-parser/dist/surveyParseTypes";

function AddressQuestionBox(props: { q: Question }) {
    const q = props.q as unknown as AddressQuestion;

    return (
        <>
            <div>
                {q!.addressQuestionType === "STATE" && (
                    <div>
                        <select
                            className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            name="value"
                        >
                            <option value=""></option>
                            {Object.keys(States).map((v) => {
                                return (
                                    <option key={v} value={v!}>
                                        {States[v]}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                )}
                {["NAMED", "NAMED_PHONE"].includes(q!.addressQuestionType) && (
                    <>
                        <div>
                            <input type="text" className="form-text" name="name" placeholder="Name" />
                        </div>
                    </>
                )}
                {["NAMED", "NAMED_PHONE", "UNNAMED", "UNAMED_PHONE"].includes(q!.addressQuestionType) && (
                    <>
                        <div>
                            <input type="text" className="form-text" name="street" placeholder="Street" />
                        </div>
                        <div>
                            <input type="text" className="form-text" name="city" placeholder="City" />
                            <select
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                name="value"
                            >
                                <option value=""></option>
                                {Object.keys(States).map((v) => {
                                    return (
                                        <option key={v} value={v!}>
                                            {States[v]}
                                        </option>
                                    );
                                })}
                            </select>
                            <input type="text" className="form-text" name="zip" placeholder="Zip" />
                        </div>
                        {/* <div>
                            <input type="text" className="form-text" name="country" placeholder="Country" />
                        </div> */}
                    </>
                )}
                {["NAMED_PHONE", "UNAMED_PHONE"].includes(q!.addressQuestionType) && (
                    <>
                        <div>
                            <input type="text" className="form-text" name="street" placeholder="Phone" />
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
export default AddressQuestionBox;
